import { useContext, useState } from 'react';
import ProgressBar from '../../ProgressBar';
import openFolder from 'assets/img/openFolder.svg';
import closeFolder from 'assets/img/closeFolder.svg';

import TrailInfo from 'classes/trail.interface';
import { AuthContext } from 'context/AuthContext';
import FolderCard from '../FolderCard';

interface Options {
  item: TrailInfo;
  percent: number;
  onclick: () => void;
}

const TrailCard = ({ item, percent, onclick }: Options) => {
  const [isOpen, setIsopen] = useState(true);
  const { contrast } = useContext(AuthContext);

  return (
    <div
      aria-label={`${item.title} - ${Math.round(percent)}% concluído`}
      role="button"
      className={`${
        contrast ? `bg-transparent border-yellow border` : 'bg-[#d9dfe4]'
      } flex mb-[18px] rounded-[10px] relative transition `}
    >
      <div
        className="bar h-full w-14 md:w-16 absolute top-0 left-0 rounded-tl-[10px] rounded-bl-[10px]"
        style={{
          background: item.extra.color?.includes('#') ? item.extra.color : `#${item.extra.color}`,
        }}
      />

      <div className="absolute top-8 left-3 md:left-4 cursor-pointer hover:opacity-80" onClick={() => setIsopen(!isOpen)}>
        <img src={isOpen ? openFolder : closeFolder} alt="" />
      </div>

      <div className="py-7 px-16 pr-4 md:px-24 w-full " role="link">
        <div className="cursor-pointer hover:opacity-80" onClick={() => setIsopen(!isOpen)}>
          <h3
            className={`${contrast ? 'text-yellow' : 'text-gray-300'} font-normal text-xl lg:text-3xl mb-2 `}
            tabIndex={0}
            aria-label={item.title}
          >
            {item.title}
          </h3>
          <div
            tabIndex={item.extra.content ? 0 : -1}
            aria-hidden={item.extra.content ? false : true}
            aria-label={item.extra.content}
            className={`${contrast ? 'text-yellow' : 'text-gray-300'} font-normal text-xs lg:text-sm leading-4 `}
            dangerouslySetInnerHTML={{ __html: item.extra.content }}
          />
          <div className="mb-4">
            <ProgressBar
              color={contrast ? '#fcfc30' : item.extra.color?.includes('#') ? item.extra.color : `#${item.extra.color}`}
              percent={percent || 0}
            />
          </div>
        </div>
        {isOpen && (
          <div>
            {item.items?.items.map(content => {
              return <FolderCard color={item.extra.color} item={content} key={content._id} isFolder={content.folder} />;
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default TrailCard;
