import { FC, useCallback, useEffect, useRef, useState } from "react";

import { useAuth } from "context/AuthContext";
import { DialogContent } from "utils/modal-board-data";
import { getQuestionsQuiz } from "services/elarning.service";
import { actionLog } from "services/actionlog.service";
import { TiTimes } from "react-icons/ti";
import { AiFillStar } from "react-icons/ai";

interface Options {
  content?: DialogContent;
  onEvent: (v?: any) => void;
  onClose: () => void;
}

const DialogQuiz: FC<Options> = ({ content, onEvent, onClose }: Options) => {
  const { user, contrast } = useAuth();
  const stepRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [questionList, setQuestionList] = useState<any[]>();
  const [step, setStep] = useState(0);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState<any>();
  const [currentQuestionAnswered, setCurrentQuestionAnswered] = useState<any>();
  const [currentGrade, setCurrentGrade] = useState(0);
  const [score, setScore] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      // console.log(content);
      setStep(0);
    }, 300);
  }, []);

  useEffect(() => {
    setFocus();
  }, []);

  function setFocus() {
    if (!!stepRef && !!stepRef.current) {
      stepRef.current.querySelector(`h5`)?.focus();
    }
  }

  async function handleAnswer(item: any) {
    if (!user || currentQuestionAnswered) return;

    if (item.grade === 1) {
      setScore(score + 1);
    }
    setCurrentGrade(Number(item.grade));
    setCurrentQuestionAnswered(item.answer);
  }

  function handleNextQuestion() {
    if (!questionList) return;

    setCurrentQuestionAnswered(undefined);

    if (questionIndex + 1 >= questionList?.length) {
      setStep(2);
      submitResult(score, Number(questionList?.length));
    } else {
      setQuestionIndex(questionIndex + 1);
      setFocus();
    }
  }

  const submitResult = useCallback(
    async (result: number, listLength: number) => {
      const obj = {
        id_content: content?._quiz_id,
        percent: (result / listLength) * 100,
      };

      actionLog("me", "complete_quiz", obj);
      onEvent();
      setStep(2);
    },
    [content?._id, user?._id]
  );

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      const questions = await getQuestionsQuiz(String(content?._id));

      const shuffledQuestions = questions.map((item: any) => {
        return {
          ...item,
          choices: item.choices,
        };
      });

      setQuestionList(shuffledQuestions);
    }

    if (step === 1 && !questionList && !!content) {
      loadData();
    }
  }, [content, questionList, step, submitResult]);

  function getAnswerClassName(item: any) {
    const className = contrast
      ? "bg-black text-yellow border-yellow"
      : "bg-white text-orange-600";
    const correctClassName = contrast
      ? "bg-yellow text-black"
      : "text-white bg-green-600";
    const incorrectClassName = "text-white bg-red-600";
    const markItem = contrast
      ? "bg-yellow text-black"
      : "text-white bg-[#07749D]";

    if (currentQuestionAnswered) {
      if (item.grade === 1 && currentQuestionAnswered === item.answer) {
        return correctClassName;
      } else if (currentQuestionAnswered === item.answer) {
        return markItem;
      }
      return className;
    } else {
      return `cursor-pointer ${className}`;
    }
  }

  useEffect(() => {
    try {
      setLoading(false);
      if (questionList && questionList.length > 0) {
        setCurrentQuestion(questionList[questionIndex]);
      }
    } catch (e) {}
  }, [questionList, questionIndex]);

  // TO DO: Error Boundary
  if (!content) return null;

  return (
    <div
      key={`video-${new Date().getTime()}`}
      className={`min-h-[100vmin] max-h-[90vh] md:min-h-0 overflow-y-auto flex flex-col ${
        contrast ? "bg-black border-spacing-7 border-yellow" : "bg-white"
      } rounded-xl p-1 md:p-5 lg:rounded-3xl w-11/12 lg:w-5/6`}
    >
      <div className="flex justify-end items-end mb-3">
        {/* <button onClick={onClose} className="rounded-full ">
            <TiTimes color="#69696E" size={25} />
          </button> */}
      </div>
      <div className="stars mb-4 flex items-center justify-between">
        <h3
          className={`font-bold px-4 md:p-0 leading-tight md:text-2xl ${
            contrast ? "text-yellow" : "text-black"
          } `}
          tabIndex={0}
        >
          {content?.title}
        </h3>
        <div className="flex">
          {Array.from({ length: content?.star || 0 }).map((i: any) => (
            <div className="mr-1">
              <AiFillStar color="#FCFC30" size={"25"} />
            </div>
          ))}
        </div>
      </div>
      <div
        className={`flex flex-col relative w-full h-full ${
          contrast ? "bg-black" : "bg-game"
        } `}
      >
        <div className="w-full h-full relative overflow-hidden p-4 lg:p-10">
          <div className="min-h relative h-full text-center flex flex-col justify-center items-center">
            <>
              {loading && (
                <div
                  className={` ${
                    contrast ? "text-yellow" : "text-white"
                  } bg-opacity-80 p-0 lg:p-10 rounded-xl`}
                >
                  <h1 className="text-lg lg:text-xl">Carregando...</h1>
                </div>
              )}

              {!loading && step === 0 && (
                <div className="bg-opacity-90 p-0 lg:p-10 rounded-xl min-w-2xl max-w-2xl">
                  <div className="flex flex-row items-center justify-center mb-3 p-2">
                    <h4
                      className={`${
                        contrast ? "text-yellow" : "text-white"
                      } text-base lg:text-2xl   text-center font-bold`}
                      tabIndex={0}
                    >
                      Atenção
                    </h4>
                  </div>

                  <div
                    className={`${
                      contrast ? "text-yellow" : "text-white"
                    } text-left`}
                  >
                    <p tabIndex={0}>
                      As estrelas serão distribuídas de acordo com o percentual
                      de acerto das questões, conforme a regra abaixo:
                    </p>

                    <ul className="mt-1">
                      <li tabIndex={0}>
                        de <strong>0</strong> até <strong>49%</strong> não ganha
                        estrela
                      </li>
                      <li tabIndex={0}>
                        de <strong>50%</strong> até <strong>69%</strong> ganha 1
                        estrela
                      </li>
                      <li tabIndex={0}>
                        de <strong>70%</strong> até <strong>89%</strong> ganha 2
                        estrelas
                      </li>
                      <li tabIndex={0}>
                        de <strong>90%</strong> até <strong>100%</strong> ganha
                        3 estrelas
                      </li>
                    </ul>

                    <p className="mt-1" tabIndex={0}>
                      Para receber as suas, você precisará chegar até a última
                      pergunta.
                    </p>
                  </div>

                  <div className="space-y-2 flex flex-row items-center gap-3 justify-center mt-5">
                    <button
                      onClick={(e) => setStep(1)}
                      className={`${
                        contrast
                          ? "text-yellow bg-black border border-yellow"
                          : "bg-blue text-white"
                      }text-base p-3 rounded mt-3 cursor-pointer`}
                    >
                      Iniciar quiz
                    </button>
                    <button
                      onClick={onClose}
                      className={`${
                        contrast
                          ? "text-yellow bg-black border border-yellow"
                          : "text-white"
                      }text-base p-3 border rounded mt-3 cursor-pointer`}
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              )}

              {!loading && currentQuestion && step === 1 && (
                <div
                  className="bg-opacity-90 p-0 lg:p-10 rounded-xl min-w-2xl max-w-2xl"
                  ref={stepRef}
                >
                  <div
                    className={`flex flex-row items-center justify-center ${
                      contrast ? "bg-black text-yellow" : "bg-white"
                    } rounded-full text-center shadow-xl mb-3 p-2`}
                  >
                    <h5
                      className={`text-base lg:text-xl ${
                        contrast ? "text-yellow" : "text-orange-500"
                      }  font-bold`}
                      tabIndex={0}
                    >
                      Questão {questionIndex + 1} de {questionList?.length}
                    </h5>
                  </div>
                  <div
                    className={`${
                      contrast
                        ? "bg-black border border-yellow text-yellow"
                        : "bg-white text-black"
                    } p-4 rounded-xl shadow-xl `}
                  >
                    <p
                      className="text-base lg:text-xl"
                      tabIndex={0}
                      dangerouslySetInnerHTML={{
                        __html: currentQuestion.question,
                      }}
                    />
                  </div>
                  <div
                    className={`py-2 font-bold ${
                      contrast ? "text-yellow" : "text-white"
                    }  text-sm lg:text-lg`}
                    tabIndex={0}
                  >
                    Escolha a sua resposta:
                  </div>
                  <div className="space-y-2">
                    {currentQuestion.type === "MULTIPLE_CHOICE" ? (
                      currentQuestion.choices.map((item: any) => (
                        <div
                          key={item._id}
                          tabIndex={0}
                          role="button"
                          onClick={() => handleAnswer(item)}
                          className={`p-2 ${getAnswerClassName(
                            item
                          )} border rounded-xl text-base lg:text-lg`}
                        >
                          {item.label}
                        </div>
                      ))
                    ) : currentQuestion.type === "TRUE_FALSE" ? (
                      <>
                        <div
                          onClick={() =>
                            handleAnswer({
                              answer: "1",
                              label: "Verdadeiro",
                              grade: 1,
                            })
                          }
                          className={`p-2 ${getAnswerClassName({
                            answer: "1",
                            label: "Verdadeiro",
                            grade: 1,
                          })} border rounded-xl text-base lg:text-lg`}
                        >
                          Verdadeiro
                        </div>
                        <div
                          onClick={() =>
                            handleAnswer({
                              answer: "2",
                              label: "Falso",
                              grade: 0,
                            })
                          }
                          className={`p-2 ${getAnswerClassName({
                            answer: "2",
                            label: "Falso",
                            grade: 0,
                          })} border rounded-xl text-base lg:text-lg`}
                        >
                          Falso
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  {currentQuestionAnswered && (
                    <div className="mt-4">
                      <button
                        onClick={handleNextQuestion}
                        className={`text-base p-3 ${
                          contrast
                            ? "bg-black border-yellow border text-yellow"
                            : "bg-[#002D4B] text-white"
                        }  rounded mt-3`}
                      >
                        Próxima questão
                      </button>
                    </div>
                  )}
                </div>
              )}

              {step === 2 && (
                <div className="text-white  bg-opacity-80 p-10 rounded-xl space-y-3">
                  <h1
                    className={`${
                      contrast ? "text-yellow" : "text-white"
                    } text-3xl`}
                  >
                    Fim do quiz
                  </h1>
                  <div className="leading-none py-3">
                    <div
                      className={`text-xl ${
                        contrast ? "text-yellow" : "text-orange-500"
                      } `}
                    >
                      Respostas corretas:
                    </div>
                    <div
                      className={`text-2xl font-bold ${
                        contrast ? "text-yellow" : "text-white"
                      }`}
                    >
                      {score} de {questionList?.length}
                    </div>
                    <div
                      className={`text-2xl font-bold ${
                        contrast ? "text-yellow" : "text-white"
                      }`}
                    >
                      percentual de acerto{" "}
                      {((score / questionList!.length) * 100).toFixed(0)}%
                    </div>
                    <div
                      className={`text-2xl font-bold ${
                        contrast ? "text-yellow" : "text-white"
                      }`}
                    >
                      <button
                        onClick={onClose}
                        className={`p-3 ${
                          contrast
                            ? "bg-black border-yellow border text-yellow"
                            : "bg-green-600 text-white"
                        }  rounded mt-3`}
                      >
                        CONCLUIR
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DialogQuiz;
