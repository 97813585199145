import { toast } from 'react-toastify';
import { ActionLog } from 'types/funifier';
import { api } from './api';

export const actionLog: any = (
  userId: string,
  actionId: string,
  attributes?: any,
  notificationType = 'modal',
  notificationMessage = ''
) => {
  return new Promise((resolve, reject) => {

    const _attributes = Object.assign({}, attributes);


    const payload = {
      userId,
      actionId: actionId,
      attributes: _attributes,
    };

    api
      .post('/action/log', payload)
      .then((response) => {
          const event = new Event('reload:user');
          document.dispatchEvent(event);

        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  });
};

export const actionLogBulk = (
  userId: string | undefined,
  actionId: string,
  attributes?: any
) => {
  return new Promise((resolve, reject) => {
    const _attributes = Object.assign({}, attributes);

    const payload = {
      userId,
      actionId: actionId,
      time: new Date().getTime(),
      attributes: _attributes,
    };

    api
      .post('/action/log/bulk?async=false', [payload])
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  });
};

export async function getActions(player:string, actionId:string): Promise<ActionLog[]> {
  try {
    const obj = {
      userId:player,
      actionId:actionId,
    };
    const { data } = await api.post(`database/action_log/aggregate?strict=true`, [
      {
        $match:obj
      }
    ]);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getActionById(id: string): Promise<ActionLog> {
  try {
    const { data } = await api.get(
      `database/action_log?q=_id:'${id}'&strict=true`
    );
    return data[0];
  } catch (e) {
    throw e;
  }
}

export async function updateAction(action: ActionLog): Promise<ActionLog> {
  try {
    const { data } = await api.put(`database/action_log`, action);
    return data[0];
  } catch (e) {
    throw e;
  }
}

export const actionLogQuest = (
  operation: string,
  player: string,
  item: string
) => {
  return new Promise((resolve, reject) => {
    const payload = {
      operation: operation,
      item: item,
      player: player,
    };

    api
      .put('/database/social_quests_log__c', payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  });
};
