import { useModal } from "context/ModalContext";
import { AnimatePresence, motion } from "framer-motion";
import Select, { components } from "react-select";
import { toast } from "react-toastify";

import { AuthContext } from "context/AuthContext";
import { useContext, useEffect, useRef, useState } from "react";
import { LuImagePlus } from "react-icons/lu";
import { actionLog } from "services/actionlog.service";
import { userMyEquipes } from "services/auth.service";
import { uploadGlobalFile } from "services/files.service";
import { getThemes, saveOrUpdateIdea } from "services/ideia.service";
import imagesGallery, { imagesGalleryDescription } from "./const";
import "./style.scss";

export default function ModalIdeia() {
  const elModal = useRef<HTMLDivElement>(null);
  const modalImagem = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [modalGalery, setModalGalery] = useState({
    open: false,
    img: "",
  });
  const [themes, setThemes] = useState([]);
  const [options, setOptions] = useState([]);
  const [hasImage, setHasImage] = useState(false);
  const {
    user,
    equipeUsersLogged,
    refreshTrail,
    setRefreshTrail,
    setAllowAddIdea,
    setMyIdeaOrContributte,
    refreshUser,
  } = useContext(AuthContext);

  const {
    setShowModalAddIdeia,
    modalData,
    showModalAddIdeia,
    refreshListIdeias,
    setRefreshListIdeias,
  } = useModal();

  useEffect(() => {
    if (showModalAddIdeia && !!elModal && elModal.current) {
      const modalElement = elModal?.current;
      const focusableElements = elModal.current.querySelectorAll(
        'button,h2, input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );

      const firstElement = focusableElements[0] as HTMLElement;
      const lastElement = focusableElements[
        focusableElements.length - 1
      ] as HTMLElement;

      const handleTabKeyPress = (event: any) => {
        if (event.key === "Tab") {
          if (event.shiftKey && document.activeElement === firstElement) {
            event.preventDefault();
            lastElement?.focus();
          } else if (
            !event.shiftKey &&
            document.activeElement === lastElement
          ) {
            event.preventDefault();
            firstElement?.focus();
          }
        }
      };

      const handleEscapeKeyPress = (event: any) => {
        if (event.key === "Escape") {
          setShowModalAddIdeia(false);
        }
      };

      modalElement.addEventListener("keydown", handleTabKeyPress);
      modalElement.addEventListener("keydown", handleEscapeKeyPress);

      return () => {
        modalElement.removeEventListener("keydown", handleTabKeyPress);
        modalElement.removeEventListener("keydown", handleEscapeKeyPress);
      };
    }

    // setTimeout(() => {
    //   elModal?.current?.querySelector('h2')?.focus();
    // }, 300);

    // if (!!modalImagem && modalImagem.current) {
    //   setTimeout(() => {
    //     modalImagem?.current?.querySelector('h2')?.focus();
    //   }, 300);
    // }
  }, [showModalAddIdeia]);

  useEffect(() => {
    if (modalGalery.open) {
      const modalImages = modalImagem.current?.querySelector("h2");
      modalImages?.focus();
    }
  }, [modalGalery.open]);

  const [form, setForm] = useState({
    _id: "",
    title: "",
    content: "",
    file: null,
    namefile: "",
    theme: "",
    participants: [],
    image: null,
    nameImage: "",
    video: "",
  });

  useEffect(() => {
    if (modalData) {
      let participants = [];

      if (modalData.participants && modalData.participants.length > 0) {
        participants = modalData.participants.map((item: any) => {
          return {
            ...item,
            isDisabled: true,
            isFixed: true,
          };
        });
      }

      const data = {
        _id: modalData._id,
        title: modalData.title,
        content: modalData.content,
        file: null,
        namefile: modalData.file || "",
        theme: modalData.theme,
        participants: participants,
        image: null,
        nameImage: modalData.image || "",
        video: modalData.video || "",
        created_at: modalData.created_at ? modalData.created_at : new Date(),
        updated_at: new Date(),
      };
      setForm(data);

      setModalGalery({
        img: modalData.image || "",
        open: false,
      });
      setIsEdit(true);
    }
  }, [modalData]);

  useEffect(() => {
    getListThemes();
    const showParticipantes: any = [];
    if (user?.teams) {
      userMyEquipes(user?.teams[0])
        .then((response) => {
          for (const e of response) {
            const newObj = {
              value: e._id,
              label: e.name,
            };
            showParticipantes.push(newObj);
          }
        })
        .finally(() => {
          setOptions(
            showParticipantes.filter((item: any) => {
              return item.value !== user?._id;
            })
          );
        });
    }
  }, [user, user?.teams]);

  function getListThemes() {
    getThemes()
      .then((resp) => {
        if (resp.length > 0) {
          setThemes(resp);
        }
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 300);
      });
  }

  const CustomIndicator = () => {
    return (
      <img
        aria-hidden="true"
        src={require("../../assets/img/participante.png")}
        alt="Ícone"
      />
    );
  };

  function clean(obj: any) {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  }

  async function addIdeia(e: any) {
    e.preventDefault();
    setLoading(true);
    let fileUpload;

    if (form.title === "") {
      toast.error("O campo título é obrigatório!");
      setLoading(false);
      return;
    }

    if (form.content === "") {
      toast.error("O campo Tema é obrigatório!");
      setLoading(false);
      return;
    }
    if (form.theme === "") {
      toast.error("Selecione um tema!");
      setLoading(false);
      return;
    }

    if (form.file) {
      const upFile = await uploadGlobalFile(form.file, "file_ideia");
      if (!upFile) {
        setLoading(false);
        toast.error("Não foi possivel enviar arquivo");
        return false;
      }
      fileUpload = upFile;
    }

    let imageUpload;
    if (form.image) {
      const upFile = await uploadGlobalFile(form.image, "file_ideia");
      if (!upFile) {
        setLoading(false);
        toast.error("Não foi possivel enviar imagem");
        return false;
      }
      imageUpload = upFile;
    }

    let payload = {
      ...form,
      player: user && user._id,
      file:
        fileUpload && fileUpload.url
          ? fileUpload.url
          : modalData && modalData.file
          ? modalData.file
          : "",
      image:
        imageUpload && imageUpload.url
          ? imageUpload.url
          : modalData && modalData.image
          ? modalData.image
          : "",
      participants: form.participants
        ? form.participants
        : modalData.participants
        ? modalData.participants
        : [],
      updated_at: new Date(),
    };

    if (isEdit === false) {
      payload = {
        ...payload,
        ...{
          created_at: new Date(),
        },
      };
    }

    if (modalGalery.img !== "") {
      payload = { ...payload, ...{ image: modalGalery.img } };
    }

    if (payload._id.length == 0) {
      // @ts-expect-error: fixing validation data
      delete payload["_id"];
    }

    saveOrUpdateIdea(payload)
      .then((resp) => {
        setAllowAddIdea(false);
        setMyIdeaOrContributte(true);
        setShowModalAddIdeia(false);
        toast.success(
          `${
            isEdit
              ? `Sua ideia foi editada com sucesso!`
              : `Sua ideia foi salva!`
          }`
        );
        setRefreshListIdeias(true);
        if (!payload._id) {
          actionLog("me", "create_idea", {});
        }
      })
      .finally(() => {
        refreshUser();
        setRefreshTrail(true);

        setTimeout(() => {
          setLoading(false);
        }, 300);
      });
  }

  const onChangeParticipants = (inputValue: any, actionMeta: any) => {
    if (
      actionMeta.action === "remove-value" &&
      actionMeta.removedValue.isFixed
    ) {
      return;
    }

    setForm({ ...form, participants: inputValue });
  };

  const FormImage = () => {
    let imageUrl = "";

    if (modalGalery.img !== "") {
      imageUrl = modalGalery.img;
    } else if (form.image) {
      imageUrl = URL.createObjectURL(form.image);
    } else if (modalData && modalData.image) {
      imageUrl = modalData.image;
    }

    if (imageUrl !== "") {
      return <img aria-hidden src={imageUrl} alt="" />;
    }

    return (
      <div
        aria-hidden="true"
        className="p-5 flex items-center justify-center rounded-full bg-gray-100"
      >
        <LuImagePlus className="text-gray-600" size={100} />
      </div>
    );
  };

  const MultiValueRemove = (props: any) => {
    return !props.data.isFixed ? (
      <components.MultiValueRemove {...props} />
    ) : null;
  };

  const handleKeyDown = (evt: any) => {
    if (evt.key === "Enter") {
      // Faça algo quando a tecla Enter for pressionada
      setModalGalery({ ...modalGalery, open: true });
    }
  };

  useEffect(() => {
    document.getElementById("root")?.setAttribute("aria-hidden", "true");
    return () => {
      document.getElementById("root")?.removeAttribute("aria-hidden");
    };
  }, []);

  return (
    <div
      aria-modal="true"
      role="dialog"
      className="main-modalIdeia justify-center flex items-center h-full"
      ref={elModal}
    >
      <AnimatePresence>
        <motion.div
          initial={{ transform: "translateY(100%)", opacity: 0 }}
          animate={{ transform: "translateY(0)", opacity: 1 }}
          exit={{ opacity: 0 }}
          className="form-add-ideia max-h-[90vh] overflow-y-auto shadow-md bg-white animate slide-in-from-bottom-0"
        >
          <button
            className="close"
            aria-label={
              modalData
                ? "Fechar modal de Editar ideia"
                : `Fechar modal de Envie sua ideia`
            }
            onClick={() => setShowModalAddIdeia(!showModalAddIdeia)}
          >
            X
          </button>
          <form className="content-form" onSubmit={addIdeia}>
            <h2 className="text-base text-purple font-bold" tabIndex={0}>
              {modalData ? "Editar ideia" : `Envie sua ideia`}
            </h2>
            <div className="boxheader flex-col lg:flex-row items-center lg:items-start">
              <div className="figure formGroup">
                <figure
                  tabIndex={0}
                  aria-label="Anexar imagem da idéia"
                  role="button"
                  className="transition-all translate-x-3 hover:scale-105 cursor-pointer"
                  onClick={() => setModalGalery({ ...modalGalery, open: true })}
                  onKeyDown={handleKeyDown}
                >
                  <FormImage />
                </figure>
              </div>
              <div className="box">
                <div className="formGroup">
                  <input
                    type="text"
                    value={form.title}
                    onChange={(e: any) =>
                      setForm({ ...form, title: e.target.value })
                    }
                    placeholder="Título"
                    disabled={loading || isEdit}
                  />
                </div>
                <div className="formGroup">
                  <textarea
                    placeholder="Redija sua ideia"
                    disabled={loading || isEdit}
                    value={form.content}
                    onChange={(e: any) =>
                      setForm({ ...form, content: e.target.value })
                    }
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="formGroup">
              <label
                htmlFor="file-upload"
                className={`custom-file-upload break-words ${
                  loading ? "disabled" : ""
                }`}
                tabIndex={0}
                role="button"
              >
                {form.namefile !== "" ? form.namefile : "Anexar arquivo"}
                <input
                  id="file-upload"
                  placeholder={
                    form.namefile !== "" ? form.namefile : "Anexar arquivo "
                  }
                  type="file"
                  disabled={loading}
                  onChange={(e: any) => {
                    const file = e.target.files[0];
                    setForm({ ...form, file: file, namefile: file.name });
                  }}
                />
              </label>
            </div>
            <div className="formGroup">
              <select
                name=""
                id=""
                // placeholder="Selecione o tema"
                disabled={loading}
                value={form.theme}
                onChange={(e: any) =>
                  setForm({ ...form, theme: e.target.value })
                }
              >
                <option value="">Selecione o tema</option>
                {themes.map((e: any, index: number) => (
                  <option value={e._id} key={index}>
                    {e.title}
                  </option>
                ))}
              </select>
            </div>
            <div className="formGroup">
              <Select
                isClearable={false}
                options={options}
                isMulti
                placeholder="Adicionar participantes"
                defaultValue={
                  !!modalData && !!modalData.participants
                    ? modalData.participants
                    : ""
                }
                className="select"
                isDisabled={loading}
                onChange={onChangeParticipants}
                components={{
                  MultiValueRemove,
                  IndicatorSeparator: () => null,
                  DropdownIndicator: CustomIndicator,
                }}
                aria-label="Adicionar participantes, digite o nome dos participantes e dê ENTER pra selecionar, repita para adicionar outros participantes e dê TAB pra ir para o proximo campo"
              />
            </div>
            <div className="formGroup">
              <input
                type="text"
                value={form.video}
                onChange={(e: any) =>
                  setForm({ ...form, video: e.target.value })
                }
                placeholder="Inserir link de vídeo"
                disabled={loading}
              />
            </div>
            <div className="formGroup actions">
              <button disabled={loading}>
                {loading ? "Enviando..." : "Enviar"}
              </button>
            </div>
          </form>
        </motion.div>
      </AnimatePresence>
      {modalGalery.open && (
        <div
          aria-modal="true"
          role="dialog"
          className="modal-galery"
          ref={modalImagem}
        >
          <div className="content-modalGalery max-w-[900px]">
            <button
              onClick={() => setModalGalery({ ...modalGalery, open: false })}
              className="close"
              aria-label="Fechar modal de galeria"
            >
              X
            </button>
            <h2 className="text-gray-600 text-[1.5em] mb-5" tabIndex={0}>
              Escolha uma imagem abaixo
            </h2>
            <div className="list-photos">
              {imagesGallery.map((item: any, index: number) => (
                <figure
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    setForm({ ...form, image: null, nameImage: "" });
                    setModalGalery({
                      ...modalGalery,
                      open: false,
                      img: item,
                    });
                  }}
                >
                  <img src={item} alt={imagesGalleryDescription[index]} />
                </figure>
              ))}
            </div>
            <div className="text-center my-10">
              <h2 className="text-gray-600 text-[1.5em] border-b-2">
                <span className="relative p-2 bg-[#ffffff] rounded-full top-[25px] px-3 border-2">
                  Ou
                </span>
              </h2>
            </div>
            <h2 className="text-gray-600 text-[1.5em]" tabIndex={0}>
              Enviar uma Imagem
            </h2>
            <div className="actions form-group">
              <label tabIndex={0}>
                <span>Selecionar uma imagem</span>
                <input
                  id="file-upload-imagem"
                  type="file"
                  disabled={loading}
                  onChange={(e: any) => {
                    const file = e.target.files[0];
                    setForm({ ...form, image: file, nameImage: file.name });
                    setModalGalery({ ...modalGalery, open: false, img: "" });
                  }}
                />
              </label>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
