import { api } from './api';

export async function getHighlights() {
  const { data } = await api.get(`database/featured_content__c`);

  return data[0];
}

export async function getContentHighlights(content: string) {
  const { data } = await api.post(`database/folder_content/aggregate`, [{ $match: { content: content } }]);

  return data[0]._id;
}
