import { api } from './api';

export async function GetCertificate() {
  const { data } = await api.get('database/certification_style__c');

  return data;
}

export async function GetIsPlayerHasCompletedStory(id: string) {
  const { data } = await api.post('database/folder_log/aggregate', [
    {
      $match: {
        item: 'Ez25BDg',
        status: 'done',
        player: id,
      },
    },
  ]);

  return data;
}
