interface FunifierClient {
	init: (
		params: { apiKey: string; service: string },
		callback: () => void,
	) => void;
	track: (actionId: string, attributes: unknown) => void;
	widget: {
		render: (
			payload: unknown,
			callback: (err: unknown, data: unknown) => void,
		) => void;
	};
	auth: {
		authenticate: (
			credentials: { grant_type: string; client_secret: string },
			callback: (err: unknown, data: unknown) => void,
		) => void;
		setAuthorization: (token: string) => void;
		getAuthorization: () => string;
		logout: () => void;
	};
}

interface FunifierWindowProps {
	Funifier?: FunifierClient;
	FUNIFIER_ENV?: {
		apikey: string;
		service: string;
	};
}

const _env = window as FunifierWindowProps;

export { _env };
