import { BrowserRouter as Router } from 'react-router-dom';

import { AuthProvider } from './context/AuthContext';
import { RouteList } from './routes';

import 'react-toastify/dist/ReactToastify.css';
import 'assets/fonts/index.scss';
import './App.scss';

import { ModalProvider } from 'context/ModalContext';
import ToastProvider from 'context/ToastContext';
import { WebsocketProvider } from 'context/WebsocketContext';
import { ConfigProvider } from 'context/ConfigContext';
import { WebsocketNotificationProvider } from 'context/WebsocketNotificationContext';
import { IPublicClientApplication } from '@azure/msal-browser';
import { ControlLayout } from 'components';
import 'react-datepicker/dist/react-datepicker.css';
import { FunifierClientLoad } from 'utils/funifier-client';

type AppProps = {
  pca?: IPublicClientApplication;
  configData: any;
};

FunifierClientLoad();

const App = ({ pca, configData }: AppProps) => (
  <Router>
    <ConfigProvider configData={configData}>
      <AuthProvider>
        <WebsocketNotificationProvider>
          <WebsocketProvider>
            <ModalProvider>
              <ControlLayout>
                <RouteList msalInstance={pca} />
              </ControlLayout>
              <ToastProvider />
              {/* <Achievement /> */}
            </ModalProvider>
          </WebsocketProvider>
        </WebsocketNotificationProvider>
      </AuthProvider>
    </ConfigProvider>
  </Router>
);

export default App;
