import { getToken } from 'utils/tokenCookies';
import { _env } from './funifier-env';

let FunifierWidgetRenderMemo: Map<string, boolean>;
FunifierWidgetRenderMemo = new Map<string, boolean>().set('widgetId', true);

export const FunifierEvent = {
  READY: 'funifier:ready',
};

export function FunifierClientLoad() {
  return new Promise(resolve => {
    if (_env.Funifier !== undefined) {
      resolve(true);
    } else {
      const script = document.createElement('script');
      script.src = 'https://client2.funifier.com/v3/funifier.js';
      script.async = true;
      document.body.appendChild(script);
      script.onload = () => {
        resolve(true);
      };
    }
  });
}

export async function FunifierClientSetup() {
  try {
    const apiKey: string = process.env.REACT_APP_APIKEY || '';
    const service: string = `https://${process.env.REACT_APP_SERVICE_URL}.funifier.com` || '';

    FunifierWidgetRenderMemo = new Map<string, boolean>();

    if (!apiKey || !service) throw new Error('Funifier not configured');

    await FunifierClientLoad();

    if (!_env.Funifier) throw new Error('Funifier not loaded');

    _env.Funifier?.init({ apiKey, service }, () => {
      FunifierAuthenticate();
      document.dispatchEvent(new CustomEvent(FunifierEvent.READY));
    });
  } catch (err) {
    console.warn(`ATTENTION: ${(err as Error).message}`);
  }
}

export async function FunifierAuthenticate() {
  try {
    if (!_env.Funifier) throw new Error('Funifier not loaded');
    const session = getToken();
    _env.Funifier?.auth.setAuthorization(`Bearer ${session}`);
  } catch (err) {
    console.warn(`ATTENTION: ${(err as Error).message}`);
  }
}

export function FunifierWidgetRender(id: string, contentId?: string, callback?: (err: unknown, data: unknown) => void) {
  try {
    if (!_env.Funifier) throw new Error('Funifier not loaded');
    if (FunifierWidgetRenderMemo.get(id)) {
      return;
    }
    FunifierWidgetRenderMemo.set(id, true);
    FunifierAuthenticate();
    _env.Funifier?.widget.render(
      {
        widget: id,
        selector: '#widget-wrapper',
        bind: 'append',
        contentId: contentId,
      },
      (err, data) => {
        FunifierWidgetRenderMemo.delete(id);
        if (callback) callback(err, data);
      },
    );
  } catch (err) {
    throw new Error(`${(err as Error).message}`);
  }
}
