import { useContext, useEffect, useState } from 'react';
import openFolder from 'assets/img/openFolder.svg';

import { AuthContext } from 'context/AuthContext';
import { ProgressItemInfo } from 'classes/progress.interface';
import ContentCard from '../ContentCard';
import { getFolderAndLog } from 'services/elarning.service';
import BoardInfo from 'classes/board.interface';

interface Options {
  item: ProgressItemInfo;
  color: string;
  isFolder: boolean;
}

const FolderCard = ({ item, color, isFolder }: Options) => {
  const [isOpen, setIsopen] = useState(true);
  const [itens, setItens] = useState<BoardInfo[]>([]);

  const { contrast } = useContext(AuthContext);
  const { user } = useContext(AuthContext);

  async function loadData() {
    try {
      const itens = await getFolderAndLog(String(user?._id), String(item._id));
      setItens(itens);
    } finally {
    }
  }

  function transformToBoardInfo(progressItem: ProgressItemInfo): BoardInfo {
    return {
      _id: progressItem._id,
      type: progressItem.type, // Mapeando 'type' diretamente
      content: progressItem.content, // Mapeando 'content' diretamente
      parent: progressItem._id, // Usando '_id' como 'parent'
      title: progressItem.title, // Mapeando 'title' diretamente
      position: progressItem.percent, // Podemos usar o 'percent' para o 'position'
      extra: {
        total: progressItem.total,
        done: progressItem.done,
      },
    };
  }

  useEffect(() => {
    loadData();
  }, []);

  const boardInfo = transformToBoardInfo(item);

  return (
    <>
      {isFolder ? (
        <div key={item._id} className="flex flex-col lg:flex-row lg:items-center bg-white mb-4 rounded-lg p-2 md:p-4 ">
          <div className="w-full mr-4 flex flex-row">
            <div onClick={() => setIsopen(!isOpen)} className="flex flex-col">
              {item.folder && (
                <div
                  style={{
                    background: color?.includes('#') ? color : `#${color}`,
                  }}
                  className="rounded-full h-10 w-10 md:h-12 md:w-12 flex justify-center items-center md:mr-4 mr-2"
                >
                  <img className="p-2" src={openFolder} alt="" />
                </div>
              )}
            </div>
            <div
              className={`${contrast ? 'text-yellow' : 'text-gray-300'} font-normal text-lg lg:text-2xl mb-2`}
              tabIndex={0}
              aria-label={item.title}
            >
              <div className="leading-none mb-4" onClick={() => setIsopen(!isOpen)}>
                <p>{item.title}</p>
                <span className="font-bold text-xs">({item.percent}% concluído)</span>
                <span className="border-b w-full flex" />
              </div>
              {isOpen &&
                item.items &&
                itens.map((contentItem, index: number) => {
                  return <ContentCard color={color} contentItem={contentItem} key={contentItem._id} number={index + 1} />;
                })}
            </div>
          </div>
        </div>
      ) : (
        <div key={item._id} className="flex flex-col lg:flex-row lg:items-center bg-white mb-4 rounded-lg p-4">
          <ContentCard contentItem={boardInfo}  color={color} number={0}/>
        </div>
      )}
    </>
  );
};

export default FolderCard;
