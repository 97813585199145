import { AuthContext } from 'context/AuthContext';
import { useContext, useEffect, useState } from 'react';

import './style.scss';
import { useParams } from 'react-router';
import BB_ROUTES from 'routes/const';
import AdminCentralIdeia from './centralIdeias';
import AdminMinhaEquipe from './MinhaEquipe';
import ModalNotificationCustom from 'components/ModalNotificaionCustom';

const AdministrationPage = () => {
  const [pageCurrent, setPageCurrent] = useState('');
  const { setBreadCrumbItens, setPageTitle } = useContext(AuthContext);
  const params = useParams();

  useEffect(() => {
    setBreadCrumbItens([]);
    setPageTitle('Administração');
  }, [setBreadCrumbItens]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToTop();
  }, [params]);

  function goTo(type: string, name: string) {
    setBreadCrumbItens([
      {
        isFirst: true,
        name: 'Administração / ',
        active: false,
        path: BB_ROUTES.HOME(),
      },
      {
        isFirst: false,
        name: ' ' + name,
        active: false,
        path: '/administration',
      },
    ]);
    setPageCurrent(type);
  }

  return (
    <div className="main-administration ">
      <section className=" py-10  mb-[18px] rounded-[10px] relative transition">
        <div className="actions">
          {/* <button
            className={`${pageCurrent === 'admIdeia' && 'active'}`}
            onClick={() => goTo('admIdeia', ' Central de ideias')}
          >
            Central de ideias
          </button>
          <button
            className={`${pageCurrent === 'admEquipe' && 'active'}`}
            onClick={() => goTo('admEquipe', ' Minha Equipe')}
          >
            Minha equipe
          </button> */}
        </div>
      </section>
      <section className="currentAdm">
        {/* {pageCurrent === 'admIdeia' && <AdminCentralIdeia />}
        {pageCurrent === 'admEquipe' && <AdminMinhaEquipe />} */}
      </section>
      <ModalNotificationCustom />
    </div>
  );
};

export default AdministrationPage;
