import { useAuth } from 'context/AuthContext';
import { useEffect, useState } from 'react';
import BB_ROUTES from 'routes/const';

import './styles.scss';
import { GetBadgess } from 'services/badges.service';
import { ReactComponent as Star } from '../../assets/img/star.svg';
import { ReactComponent as Mount } from '../../assets/img/currency.svg';

export function Badges() {
  const { user, badges, setBreadCrumbItens, setPageTitle } = useAuth();

  useEffect(() => {
    setBreadCrumbItens([
      {
        isFirst: true,
        name: 'Inicio/',
        active: false,
        path: BB_ROUTES.HOME(),
      },
      {
        isFirst: false,
        name: `Badges`,
        active: true,
        path: '/',
      },
    ]);

    setPageTitle('Badges');
  }, [setBreadCrumbItens]);

  return (
    <>
      <div className="w-full text-white text-xl font-lighter main-badges">
        <div className="content-badges">
          <table>
            <thead>
              <tr>
                <th>BADGE</th>
                <th>NOME</th>
                <th>DESAFIOS</th>
              </tr>
            </thead>
            <tbody>
              {badges?.map((item: any) => (
                <tr>
                  <td className={item.completed ? 'completed' : 'incomplet'}>
                    {item.badge?.medium?.url ? (
                      <img src={item.badge?.medium?.url} alt="badge" />
                    ) : (
                      '--'
                    )}
                  </td>
                  <td>
                    <span>{item.challenge}</span>
                  </td>
                  <td>{item.description}</td>
                </tr>
              ))}
              {!badges.length && (
                <tr>
                  <td colSpan={100}>Nenhum badges para exibir!</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
