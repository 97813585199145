import { useAuth } from 'context/AuthContext';
import { AvatarModal } from '../../components/Avatar';
// import Notifications from 'components/notifications';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BB_ROUTES from 'routes/const';
import ModalImageSocialMedia from 'components/ModalImageSocialMedia';
import { useConfig } from 'context/ConfigContext';

interface ModalContextData {
  showModal: (modalId: string, data?: any) => void;
  closeModal: () => void;
  openModal?: string;
  modalData: any;
  showModalBoard: boolean;
  setShowModalBoard: (value: boolean) => void;
  showModalAddIdeia: boolean;
  setShowModalAddIdeia: (value: boolean) => void;
  refreshListIdeias: boolean;
  setRefreshListIdeias: (value: boolean) => void;
  showModalTutorial: boolean;
  setShowModalTutorial: (value: boolean) => void;
  setModalData: (val?: any) => void;
}

interface ModalProviderProps {
  children: ReactNode;
}

export const ModalContext = createContext({} as ModalContextData);

function ModalProvider({ children }: ModalProviderProps) {
  const location = useLocation();
  const navigation = useNavigate();
  const { appearance } = useConfig();
  const { isAuthenticated, user } = useAuth();

  const [openModal, setOpenModal] = useState<string | undefined>();
  const [modalData, setModalData] = useState<any>();
  const [showModalAddIdeia, setShowModalAddIdeia] = useState<boolean>(false);
  const [refreshListIdeias, setRefreshListIdeias] = useState<boolean>(false);
  const [showModalTutorial, setShowModalTutorial] = useState<boolean>(false);
  const [showModalBoard, setShowModalBoard] = useState<boolean>(false);

  useEffect(() => {
    closeModal();
  }, [location]);

  useEffect(() => {
    const v = user?.extra.view_tutorial;
    if (!!user && user.extra && !v && appearance?.menu?.rule) {
      // setShowModalTutorial(true);
      navigation(BB_ROUTES.RULE());
    }
  }, [isAuthenticated, user]);

  function showModal(modalId: string, data?: any) {
    setModalData(data);
    setOpenModal(modalId);
  }

  function closeModal() {
    setOpenModal(undefined);
    setModalData(undefined);
  }

  return (
    <ModalContext.Provider
      value={{
        showModal,
        closeModal,
        openModal,
        modalData,
        showModalAddIdeia,
        showModalTutorial,
        showModalBoard,
        setShowModalAddIdeia,
        refreshListIdeias,
        setRefreshListIdeias,
        setShowModalTutorial,
        setShowModalBoard,
        setModalData,
      }}
    >
      {children}
      <AvatarModal />
      <ModalImageSocialMedia />
    </ModalContext.Provider>
  );
}

function useModal() {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useWebsocket must be used within a WebsocketProvider');
  }
  return context;
}

export { ModalProvider, useModal };
