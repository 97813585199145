import { ProgressInfo } from 'classes/progress.interface';
import { api } from './api';
import TrailInfo from 'classes/trail.interface';

export async function getContentAdmin(team?: any, parentId?: any, user_id?: any): Promise<TrailInfo[]> {
  let query = {};
  if (parentId) {
    query = {
      ...query,
      ...{
        $match: {
          parent: parentId,
        },
      },
    };
  } else {
    query = {
      ...query,
      ...{
        $match: {
          parent: {
            $exists: false,
          },
        },
      },
    };
  }

  const { data } = await api.post(`database/folder/aggregate`, [query]);

  if (!Array.isArray(data)) {
    return [];
  } else {
    const result: Array<TrailInfo> = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const progress = await getProgress(user_id, item._id);
      let current = Object.assign({}, item);

      if (progress) {
        current = {
          ...current,
          ...{
            total: progress.total,
            percent: progress.percent,
            done: progress.done,
          },
        };
      }
      result.push(current);
    }

    return result;
  }
}

export async function getContent(user_id?: any): Promise<TrailInfo[]> {
  const { data } = await api.post(`/find/trilhas`, {
    a: 'b',
  });

  if (!Array.isArray(data)) {
    return [];
  } else {
    const result: Array<TrailInfo> = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const progress = await getProgress(user_id, item._id);
      let current = Object.assign({}, item);
      const moduleItem = await getContentModule(item._id, user_id);
      current.modules = moduleItem;
      current.items = progress;
      if (progress) {
        current = {
          ...current,
          ...{
            total: progress.total,
            percent: progress.percent,
            done: progress.done,
          },
        };
      }
      result.push(current);
    }

    return result;
  }
}

export async function getContentModule(parentId?: any, user_id?: any, hasProgress?: boolean): Promise<TrailInfo[]> {
  const { data } = await api.post(`/find/modulos`, { parent: parentId });

  if (!Array.isArray(data)) {
    return [];
  } else {
    const result: Array<TrailInfo> = [];
    if (hasProgress) {
      for (let i = 0; i < data.length; i++) {
        const item = data[i];

        let current = Object.assign({}, item);
        const progress = await getProgress(user_id, item._id);

        if (progress) {
          current = {
            ...current,
            ...{
              total: progress.total,
              percent: progress.percent,
              done: progress.done,
            },
          };
        }
        result.push(current);
      }

      return result;
    } else {
      return data;
    }
  }
}

export async function getModuleData(moduleId: any) {
  const { data } = await api.get(`/folder/${moduleId}`);

  return data;
}

export async function getSingleTrail(paramId?: any) {
  const { data } = await api.post(`database/folder/aggregate`, [
    {
      $match: {
        _id: paramId,
      },
    },
  ]);

  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}

export async function getOneTrail(trailId?: any, profile?: any) {
  let filter = {};
  if (trailId) {
    filter = { ...filter, ...{ parent: trailId } };
  }

  if (profile) {
    filter = {
      ...filter,
      ...{
        extra: {
          sub_type: profile,
        },
      },
    };
  }
  const { data } = await api.post(`database/folder/aggregate`, [
    {
      $match: filter,
    },
  ]);

  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}

export async function getModuleContent(moduleId?: any) {
  const { data } = await api.post(`database/folder_content/aggregate`, [
    {
      $match: {
        parent: moduleId,
      },
    },
    {
      $sort: {
        position: 1,
      },
    },
  ]);

  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}

export async function getFolderLog(player: string) {
  const { data } = await api.post(`database/folder_log/aggregate`, [
    {
      $match: {
        player,
      },
    },
  ]);

  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}

export async function getQuestionsQuiz(quiz: any) {
  const { data } = await api.post('database/question/aggregate', [{ $match: { quiz: quiz } }]);

  return data;
}

export async function getProgress(player: string, folder: string): Promise<ProgressInfo> {
  const { data } = await api.post(`/folder/progress`, {
    player: player,
    folder: folder,
  });

  return data;
}

export async function getFolderAndLog(player: string, folder: string) {
  try {
    const actions = await api.post(
      `database/action_log/aggregate?strict=true`,
      [
        {
          $match: {
            userId: player,
            $or: [{ actionId: 'complete_content' }, { actionId: 'complete_quiz' }],
          },
        },
      ],
      {
        headers: {
          range: 'items=0-1000',
        },
      },
    );

    const { data } = await api.post(`/find/aggregate_folder_with_log`, {
      player: player,
      folder: folder,
    });

    const result: Array<any> = [];

    data.forEach((item: any) => {
      const logs = actions.data.filter((f: any) => {
        return f.attributes.id_content === item._id;
      });

      let current = Object.assign({}, item);

      current = { ...current, ...{ action_logs: logs[0] } };

      result.push(current);
    });

    return result;
  } catch (e) {
    console.log(`Error => ${e}`);
    return [];
  }
}

export async function getFolderContent(type: string, contentId: string) {
  let collection = type == 'quiz' ? 'quiz' : `${type}__c`;
  if (type == 'external') {
    collection = 'external_content__c';
  }

  if (type == 'game_story') {
    collection = 'story';
  }

  const { data } = await api.post(`database/${collection}/aggregate`, [
    {
      $match: {
        _id: {
          $in: [contentId],
        },
      },
    },
  ]);

  if (!Array.isArray(data)) {
    return [];
  } else {
    return data[0];
  }
}

export async function registerFolderLog(player: string | undefined, item: string, status: string, extra?: any): Promise<any> {
  if (!player || !item || !status) return null;

  const serverTime = await getServerTime();

  const payload: any = {
    item,
    player,
    status,
    extra: {
      date: serverTime,
    },
  };

  if (extra) {
    payload.extra = { ...payload.extra, ...extra };
  }

  const { data } = await api.post(`folder/log`, payload);

  const event = new Event('reload:user');
  document.dispatchEvent(event);

  return data;
}

// Obter horário do servidor - Para gravar o log corretamente (Modificar posteriormente com o Ricardo, para gravar diretamente pelo service sem a necessidade dessa requisição)
export async function getServerTime(): Promise<number> {
  const { data } = await api.post(`database/folder/aggregate`, [
    {
      $facet: {
        data: [
          {
            $match: {
              _id: 'IDInexistenteParaPegarHoraDoServidor',
            },
          },
          {
            $project: {
              _id: 1,
            },
          },
        ],
      },
    },
    {
      $project: {
        server_time: {
          $ifNull: [
            {
              $arrayElemAt: ['$data._id', 0],
            },
            '$$NOW',
          ],
        },
      },
    },
  ]);

  return data[0].server_time;
}
