
import axios from "axios";
import { api } from "./api";

type SignInRequestData = {
  username: string;
  password: string;
  name?: string;
  email: string;
  apiKey?: string;
  grant_type?: string;
};

export async function getSSOEnvironment() {
  try {
    await axios.get("https://login.intranet.bb.com.br/");
    return 'internal';
  }
  catch (e: any) {
    if (e && e.code === 'ERR_NETWORK') {
      return 'external';
    } else {
      return 'internal';
    }
  }
}

export function signInRequest(data: SignInRequestData) {
  data["apiKey"] = process.env.REACT_APP_APIKEY;
  data["grant_type"] = "password";

  return api
    .post("/auth/token", data)
    .then(async (response) => {
      await registerUser(data);
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export function fetchUser() {
  return api
    .get("/player/me/status")
    .then((response) => {
      const user = response.data;
      if (user) {
        return user;
      }
      else {
        document.dispatchEvent(new CustomEvent("signout"));
      }
    })
    .catch((error) => {
    });
}

export function registerUser(user: any) {
    const payload = {
      _id: user.username,
      email: user.email,
      name: user.name,
    };
  return api
    .post('/player', payload, {
      headers: {
        Authorization: 'Basic ' + btoa(`${process.env.REACT_APP_APIKEY}:${process.env.REACT_APP_REGISTRATION_SECRETKEY}`),
      },
    })
    .then(response => {
      const user = response.data;
      return user;
    })
    .catch(error => {});
}

export function userMyEquipes(equipe: string) {
  // FIXME implementar filtro corretamente e remover esta paginacao
  const headers = {
    range: "item=0-1000"
  }

  const payload =[
    {
        "$match": {
            "teams": {
                "$in": [equipe]
            }
        }
    },
    {
        "$lookup": {
            "from": "idea__c",
            "localField": "_id",
            "foreignField": "player",
            "as": "idea_as_player"
        }
    },
    {
        "$lookup": {
            "from": "idea__c",
            "let": {
                "player_id": "$_id"
            },
            "pipeline": [
                {
                    "$match": {
                        "$expr": {
                            "$in": [
                                "$$player_id",
                                "$participants.value"
                            ]
                        }
                    }
                }
            ],
            "as": "idea_as_participant"
        }
    },
    {
        "$match": {
            "$and": [
                {
                    "idea_as_player": {
                        "$eq": []
                    }
                },
                {
                    "idea_as_participant": {
                        "$eq": []
                    }
                }
            ]
        }
    },
    {
        "$project": {
            "idea_as_player": 0,
            "idea_as_participant": 0
        }
    },
    {
        "$project": {
            "name": 1,
            "email": 1,
            "teams": 1,
            "manager": "$extra.manager",
            "subtype": "$extra.subtype",
            "perfil": "$extra.perfil"
        }
    }
  ];

  return api
    .post("/database/player/aggregate?strict=true", payload, {headers})
    .then((response) => {
      const equipes = response.data;
      return equipes;
    })
    .catch((error) => {
    });
}

export async function recoverPasswordRequest(data: SignInRequestData) {
  data["apiKey"] = process.env.REACT_APP_APIKEY;
  data["grant_type"] = "password";

  try {
    const response = await api.post("/auth/token", data);
    return response.data.access_token;
  } catch (error) {
    console.log(error);
  }
}
