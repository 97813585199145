import { UserAvatar } from "components/user-avatar";
import { AuthContext } from "context/AuthContext";
import { useAuth } from "context/AuthContext";
import { useModal } from "context/ModalContext";
import html2canvas from "html2canvas";
import React, {
	useEffect,
	useState,
	useContext,
	useRef,
	useCallback,
} from "react";
import Cropper from "react-easy-crop";
import { TiTimes } from "react-icons/ti";
import Modal from "react-modal";
import ReactModal from "react-modal";
import getCroppedImg from "./cropImage";

import Card from "assets/img/card-social-media2.png";
import "./styles.scss";

const ModalImageSocialMedia = () => {
	const { user } = useContext(AuthContext);
	const [avatarImage, setAvatarImage] = useState<string | null>(null);

	const modalID = "image-social-media";

	const [loading, setLoading] = useState(false);
	const [modalIsOpen, setIsOpen] = useState(false);
	const [showCrop, setShowCrop] = useState(false);
	const { openModal, modalData, closeModal } = useModal();

	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [rotation, setRotation] = useState(0);
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
	const [croppedImage, setCroppedImage] = useState<string | null>(null);

	ReactModal.setAppElement("#modal");

	function handleCloseModal() {
		setLoading(false);
		closeModal();
	}

	useEffect(() => {
		if (!user) return;
		setAvatarImage(null);
		setShowCrop(false);
		setCroppedImage(null);
		setZoom(1);
		setIsOpen(openModal === modalID);
	}, [user, openModal, modalData]);

	const downloadImage = () => {
		setLoading(true);
		const avatarElement = document.getElementById("avatar");
		const cardElement = document.getElementById("card");

		if (!!avatarElement && !!cardElement) {
			const avatarImgElement = avatarElement.querySelector("div");
			const cardImgElement = cardElement.querySelector("img");

			if (!!avatarImgElement && !!cardImgElement) {
				let avatarImageUrl: string;

				if (avatarImgElement.querySelector("img")) {
					const url = avatarImgElement.querySelector("img");
					if (url) {
						avatarImageUrl = url.src;
						continueDownload(avatarImageUrl, cardImgElement);
					}
				} else {
					html2canvas(avatarImgElement).then((avatarCanvas) => {
						avatarImageUrl = avatarCanvas.toDataURL("image/png");
						continueDownload(avatarImageUrl, cardImgElement);
					});
				}
			}
		}
	};

	function continueDownload(
		avatarImageUrl: string,
		cardImgElement: HTMLImageElement,
	) {
		const cardImageUrl = cardImgElement.src;

		const loadImages = (url: string): Promise<HTMLImageElement> => {
			return new Promise((resolve, reject) => {
				const headers = new Headers();
				headers.append("Cache-Control", "no-cache");

				const requestOptions: RequestInit = {
					method: "GET",
					headers: headers,
				};

				fetch(url, requestOptions)
					.then((response) => {
						if (response.ok) {
							return response.blob();
						} else {
							reject(new Error("Erro ao carregar a imagem"));
						}
					})
					.then((blob: any) => {
						const img = new Image();
						const objectURL = URL.createObjectURL(blob);
						img.onload = () => {
							URL.revokeObjectURL(objectURL);
							resolve(img);
						};
						img.onerror = (error) => {
							URL.revokeObjectURL(objectURL);
							reject(error);
						};
						img.src = objectURL;
					})
					.catch((error) => {
						reject(error);
					});
			});
		};

		// Carrega as imagens e combina no canvas
		Promise.all([loadImages(avatarImageUrl), loadImages(cardImageUrl)])
			.then(([avatarImage, cardImage]) => {
				const canvas = document.createElement("canvas");
				canvas.width = 600;
				canvas.height = 600;
				const ctx = canvas.getContext("2d");
				if (ctx) {
					// Desenha as imagens no canvas
					ctx.drawImage(
						avatarImage,
						125,
						122, // Posição para centralizar a imagem
						350,
						355, // Dimensões da imagem
					);
					ctx.drawImage(cardImage, 0, 0, canvas.width, canvas.height);

					// Exporta a nova imagem do canvas
					const dataURL = canvas.toDataURL("image/png");
					const link = document.createElement("a");
					link.href = dataURL;
					link.download = "game-toon-card.png";
					link.click();
				}
				handleCloseModal();
				setLoading(false);
			})
			.catch((error) => {
				console.error("Erro ao carregar as imagens:", error);
				setLoading(false);
			});
	}

	const onCropComplete = useCallback(
		(croppedArea: any, croppedAreaPixels: any) => {
			setCroppedAreaPixels(croppedAreaPixels);
		},
		[],
	);

	const handleUseImage = useCallback(async () => {
		try {
			if (!avatarImage || !croppedAreaPixels) return;

			const croppedImage = await getCroppedImg(
				avatarImage, // Adicione uma verificação aqui
				croppedAreaPixels,
				rotation,
			);

			console.log("donee", { croppedImage });
			setCroppedImage(croppedImage);
			setShowCrop(false);
		} catch (e) {
			console.error(e);
		}
	}, [avatarImage, croppedAreaPixels, rotation, setCroppedImage]);

	const onClose = useCallback(() => {
		setCroppedImage(null);
	}, []);

	const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setAvatarImage(reader.result as string);
			};
			reader.readAsDataURL(file);
			setShowCrop(true);
		}
	};

	return (
		<>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => handleCloseModal()}
				contentLabel="Example Modal"
				shouldCloseOnOverlayClick={false}
				shouldCloseOnEsc={false}
				portalClassName="relative z-[600]"
				overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
				contentElement={() => (
					<div className="modalDetailBox bg-[#FFF] text-black p-8 rounded lg:w-[30%] max-w-[80%] lg:max-w-[500px] relative  h-[550px] lg:h-[650px]">
						<button
							className="absolute text-[#69696E] top-[20px] right-[20px] "
							onClick={handleCloseModal}
						>
							<TiTimes color="#69696E" size={25} />
						</button>
						<div
							className="modalBody lg:h-[80vh] h-[40vh] max-h-[480px] lg:min-h-[480px] relative mt-5 mb-2"
							id="mix"
						>
							<>
								{!!avatarImage && showCrop && (
									<div id="cropArea" className="h-full">
										<Cropper
											image={avatarImage}
											crop={crop}
											zoom={zoom}
											aspect={4 / 4}
											onCropChange={setCrop}
											onRotationChange={setRotation}
											onCropComplete={onCropComplete}
										/>
										<input
											type="range"
											value={zoom}
											min={1}
											max={3}
											step={0.1}
											aria-labelledby="Zoom"
											onChange={(e) => {
												setZoom(parseFloat(e.target.value));
											}}
											className="absolute bottom-[5px] w-[95%] left-[10px]"
										/>
									</div>
								)}
								{!showCrop && (
									<>
										<div
											className="absolute h-[316px] lg:h-[436px] z-10 mx-auto block"
											id="card"
										>
											<img
												src="https://s3.amazonaws.com/funifier/games/64c2b2d95ef07b2b9790b972/images/654a6af3dfaa1a3ff4fa13a2_original_card-social-media2.png"
												alt="card"
												className=""
											/>
										</div>
										<div
											className="absolute z-0 mx-auto left-[0] right-[0] w-[260px] h-[140px] lg:h-[260px] top-[80px]"
											id="avatar"
										>
											<div className="w-full h-full">
												{croppedImage ? (
													<img src={croppedImage} alt="Avatar" />
												) : (
													<UserAvatar
														data={{ ...user, ...user?.extra }}
														responsive={true}
													/>
												)}
											</div>
										</div>
									</>
								)}
							</>
						</div>
						<label htmlFor="">Selecione uma imagem para o seu card.</label>
						<input type="file" onChange={handleImageChange} accept="image/*" />{" "}
						<br />
						<button
							className="bg-blue saveImage"
							onClick={downloadImage}
							disabled={!!loading}
						>
							{loading ? "Baixando imagem..." : "Salvar imagem"}
						</button>
						{showCrop && (
							<button className="bg-blue saveImage" onClick={handleUseImage}>
								Usar esta imagem
							</button>
						)}
					</div>
				)}
			/>
		</>
	);
};

export default ModalImageSocialMedia;
