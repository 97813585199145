/* eslint-disable react-refresh/only-export-components */
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

interface ConfigContextData {
  auth?: any;
  appearance?: any;
}

interface ConfigProviderProps {
  children: ReactNode;
  configData: ConfigContextData;
}

export const ConfigContext = createContext({} as ConfigContextData);

function ConfigProvider({ children, configData }: ConfigProviderProps) {
  const [auth, setAuth] = useState<any>();
  const [appearance, setAppearance] = useState<any>();

  useEffect(() => {
    if (configData.auth) {
      setAuth(configData.auth);
    }

    if (configData.appearance) {
      setAppearance(configData.appearance);
    }

    if (configData.appearance?.title) {
      document.title = configData.appearance?.title;
    }
    if (configData.appearance?.layout?.bgPage) {
      document.documentElement.style.setProperty('--color-primary', configData.appearance?.layout?.bgPage);
    }

    if (configData.appearance?.images?.favicon) {
      const link: HTMLLinkElement = document.querySelector("link[rel='icon']") || document.createElement('link');
      link.rel = 'icon';
      link.href = configData.appearance?.images?.favicon;
      document.head.appendChild(link);
    }
  }, [configData]);

  return (
    <ConfigContext.Provider
      value={{
        auth,
        appearance,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
}

function useConfig() {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  return context;
}

export { ConfigProvider, useConfig };
