import gameType from 'assets/img/gameType.svg';
import textType from 'assets/img/textType.svg';
import videoType from 'assets/img/videoType.svg';
import BoardInfo from 'classes/board.interface';

import { useAuth } from 'context/AuthContext';
import { useModal } from 'context/ModalContext';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { actionLog } from 'services/actionlog.service';
import { getFolderContent, registerFolderLog } from 'services/elarning.service';

interface Options {
  contentItem: BoardInfo;
  color: string;
  number: number;
}

const ContentCard = ({ contentItem, color, number }: Options) => {
  const [isCurrentGame, setIsCurrentGame] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { showModalBoard, setShowModalBoard, setModalData } = useModal();
  const { user } = useAuth();
  const boardItemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!!boardItemRef && boardItemRef.current) {
      boardItemRef.current?.querySelector('button')?.focus();
    }
  }, [isPopoverOpen]);

  useEffect(() => {
    const isGame = [
      'game_wordsearch',
      'game_crossword',
      'game_story',
      'game_puzzle',
      'game_enigma',
      'game_memory',
      'game_codex',
      'game_runner',
    ].includes(contentItem.type);

    setIsCurrentGame(isGame);
  }, [contentItem.type]);

  const onCloseBoardItem = () => {
    setShowModalBoard(false);
    setModalData(undefined);
  };

  function viewDetail() {
    if (isCurrentGame) {
      let data = {};

      getFolderContent(contentItem.type, contentItem.content).then(resp => {
        data = {
          ...data,
          ...{
            description: resp?.description,
            title: contentItem.title,
            itemNumber: number,
            gameType: contentItem.type,
            contentType: contentItem.type,
            star: contentItem.action_logs?.attributes?.stars || 0,
            gameId: contentItem._id,
            type: contentItem.type,
          },
        };
        if (contentItem.log?.status === 'done') {
          data = { ...data, ...{ status: 'COMPLETE' } };
        } else {
          data = { ...data, ...{ status: 'UNCOMPLETE' } };
        }
        setIsPopoverOpen(false);
        setModalData({
          number: number,
          dialogContent: data,
          type: contentItem.type,
          onClose: onCloseBoardItem,
          onEvent: (e?: any) => {
            markView(e);
          },
        });
        setShowModalBoard(true);
      });
    } else {
      getFolderContent(contentItem.type, contentItem.content)
        .then(resp => {
          let data = {};
          if (resp) {
            data = {
              ...data,
              ...{
                itemNumber: number,
                _id: contentItem.content,
                _quiz_id: contentItem._id,
              },
            };
            if (contentItem.log?.status === 'done') {
              data = { ...data, ...{ status: 'COMPLETE' } };
            } else {
              data = { ...data, ...{ status: 'UNCOMPLETE' } };
            }
            if (resp?.description) {
              data = { ...data, ...{ content: resp?.description } };
            }
            if (resp.text) {
              data = { ...data, ...{ content: resp?.text } };
            }
            if (resp?.image) {
              data = { ...data, ...{ image: resp?.image } };
            }
            if (resp?.title) {
              data = { ...data, ...{ title: resp?.title } };
            }
            if (resp?.intro) {
              data = { ...data, ...{ introContent: resp?.intro } };
            }
            if (resp?.url) {
              data = { ...data, ...{ url: resp?.url } };
            }

            if (resp?.link) {
              data = { ...data, ...{ url: resp?.link } };
            }
            if (resp?.file) {
              data = { ...data, ...{ file: resp?.file } };
            }

            data = {
              ...data,
              ...{
                contentType: contentItem.type,
                star: contentItem.action_logs?.attributes?.stars || 0,
              },
            };

            setModalData({
              number: number,
              dialogContent: data,
              type: contentItem.type,
              onClose: onCloseBoardItem,
              onEvent: (e?: any) => {
                markView(e);
              },
            });
            setShowModalBoard(true);
          }
        })
        .finally(() => {
          setIsPopoverOpen(false);
        });
    }
  }

  async function markView(obj?: any) {
    if (contentItem.log?.status !== 'done' && !['external'].includes(contentItem.type)) {
      await registerFolderLog(user?._id, contentItem._id, 'done');
      toast.info(`O ${contentItem.title} foi concluído com sucesso! Seu progresso será atualizado!`, {
        onClose: () => {
          const bar = document.querySelector(`.user-progress-bar`) as HTMLDivElement;
          bar.focus();
        },
      });
    }

    const currentLog = {
      id_content: contentItem._id,
      type: contentItem.type,
    };
    if (!['quiz', 'external'].includes(contentItem.type)) {
      await actionLog('me', 'complete_content', {
        ...currentLog,
        ...obj,
      });
    }

    if (['external'].includes(contentItem.type)) {
      await actionLog('me', 'view_course', currentLog);
    }

    if (['text'].includes(contentItem.type)) {
      //  onUpdate();
    }
  }

  const selectIcontype = (type: string) => {
    switch (type) {
      case 'game':
      case 'game_crossword':
      case 'game-container':
      case 'game_story':
      case 'game_wordsearch':
      case 'game_enigma':
      case 'game_runner':
      case 'game_puzzle':
      case 'game_codex':
      case 'quiz':
        return gameType;
      case 'external':
      case 'doc':
      case 'text':
        return textType;
      case 'video':
        return videoType;
      default:
        return undefined;
    }
  };

  const gameTranslations: { [key: string]: string } = {
    game_memory: 'jogo da memória',
    game_puzzle: 'quebra-cabeça',
    game_crossword: 'palavras cruzadas',
    game_wordsearch: 'caça-palavras',
    game_codex: 'códex',
    game_enigma: 'enigma',
    game_runner: 'corrida',
    game_detective: 'detetive',
    game_story: 'história',
    phishing: 'phishing',
    quiz: 'quiz',
  };

  const getGameName = (gameType: string): string => {
    if (gameType == 'text') {
      return 'Texto';
    } else if (gameType == 'doc') {
      return 'Documento';
    } else if (gameType == 'video') {
      return 'Vídeo';
    } else if (gameType == 'external') {
      return 'Link';
    } else {
      return 'mini game - ' + gameTranslations[gameType] || 'desconhecido';
    }
  };

  return (
    <>
      <div
        key={contentItem._id}
        className="text-gray-300 text-sm lg:text-base flex my-2 hover:opacity-80"
        onClick={() => viewDetail()}
      >
        {' '}
        <div
          style={{
            background: color?.includes('#') ? color : `#${color}`,
          }}
          className="rounded-full h-10 w-10 md:h-12 md:w-12 flex justify-center items-center md:mr-4 mr-2"
        >
          <img className="p-1 md:p-2" src={contentItem.type ? selectIcontype(contentItem.type) : textType} alt="" />
        </div>
        <div className="leading-none mt-1">
          <p>{contentItem.title}</p>
          <span className="font-bold text-xs">{getGameName(contentItem.type)}</span>
        </div>
      </div>
    </>
  );
};

export default ContentCard;
