import { useAuth } from "context/AuthContext";
import Lottie from "lottie-react";
import { useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import {
  execMysteryBox,
  updateUnlockMystery,
  verifyUnlockMystery,
} from "../../services/mystery-box.service";

import congratAnimation from "assets/lottie/congratulations.json";
import countdownAnimation from "assets/lottie/countdown.json";
import giftBoxAnimation2 from "assets/lottie/gift-box-2.json";
import giftBoxAnimation from "assets/lottie/gift-box.json";
import "./style.scss";
import TabFocusTrap from "components/TabFocusTrap";

const MysteryBox = () => {
  Modal.setAppElement("#modal");

  const modalContent = useRef<HTMLDivElement>(null);
  const refEl = useRef(null);
  const { user, refreshUser } = useAuth();
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [showMystery, setShowMystery] = useState<boolean>(false);
  const [countdown, setCountdown] = useState();
  const [totalCoins, setTotalCoins] = useState<number>(0);
  const [showCongrats, setShowCongrats] = useState<boolean>(false);

  function openModal() {
    setIsOpen(true);
    updateVerify();
    execMysteryBox("me").then((resp) => {
      if (resp.achievements) {
        const a = resp.achievements.filter((e: any) => e.type === 0)[0];
        setTotalCoins(a.total);
        setTimeout(() => {
          if (!!modalContent && !!modalContent.current) {
            modalContent.current.querySelector("h2")?.focus();
          }
          setShowCongrats(true);
        }, 2300);
      }
    });
  }

  function afterOpenModal() {}

  function onFocus() {
    setTimeout(() => {
      modalContent.current?.querySelector(`h2`)?.focus();
    }, 300);
  }

  function closeModal() {
    refreshUser();
    setIsOpen(false);
    setShowMystery(false);
    loadVerifyUnlockMystery(user ? user._id : "");
  }

  useEffect(() => {
    if (user) {
      loadVerifyUnlockMystery(user._id);
    }
  }, [user, countdown]);

  function loadVerifyUnlockMystery(id: string) {
    verifyUnlockMystery(id)
      .then((resp) => {
        if (resp.length === 0) {
          setShowMystery(true);
        } else if (resp.length > 0) {
          if (hasPassed24Hours(resp[0].nextOpen)) {
            setShowMystery(true);
          }
          setCountdown(resp[0].nextOpen + 24 * 60 * 60 * 1000);
        }
      })
      .finally(() => {
        setTimeout(() => {
          //setLoading(false);
        }, 300);
      });
  }

  const hasPassed24Hours = (timestamp: any) => {
    const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000; // 24 horas em milissegundos
    const currentTime = new Date().getTime(); // Tempo atual em milissegundos

    return currentTime - timestamp > twentyFourHoursInMilliseconds;
  };

  function updateVerify() {
    const obj = {
      _id: user?._id + "321582548",
      player: user?._id,
      nextOpen: { $date: new Date().getTime() },
    };
    updateUnlockMystery(obj);
  }

  const renderer = ({ hours, minutes, seconds, completed }: any) => {
    return (
      <span>
        {hours}:{minutes}:{seconds}
      </span>
    );
  };

  return (
    <>
      <div className="mysteryBox">
        {showMystery ? (
          <button
            onClick={openModal}
            aria-label="Resgatar meu presente"
            tabIndex={0}
          >
            <Lottie animationData={giftBoxAnimation} loop={true} />
          </button>
        ) : (
          <>
            <div
              tabIndex={0}
              aria-label="Você poderá abrir o seu novo presente em "
            >
              <p>Próximo presente em:</p>
              {!!countdown && (
                <Countdown date={countdown} renderer={renderer} />
              )}
            </div>
          </>
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        portalClassName="relative z-[600]"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
        contentElement={() => (
          <div className="modalDetailBox " ref={modalContent}>
            <TabFocusTrap isOpen={modalIsOpen} setIsOpen={openModal}>
              {!!showCongrats && totalCoins > 0 && (
                <div className="text-center text-[2em]">
                  <Lottie
                    animationData={congratAnimation}
                    loop={true}
                    className="-mt-[100px]"
                    onLoopComplete={(e) => {
                      if (!!modalContent && !!modalContent.current) {
                        modalContent.current.querySelector("h2")?.focus();
                      }
                    }}
                  />
                  <h2 tabIndex={0} ref={refEl}>
                    Parabéns!!
                  </h2>
                  <p tabIndex={0}>
                    Você acabou de ganhar {totalCoins}
                    {totalCoins > 1 ? " moedas" : " moeda"}
                  </p>
                </div>
              )}

              {!showCongrats && (
                <Lottie
                  animationData={giftBoxAnimation2}
                  loop={false}
                  onAnimationEnd={onFocus}
                />
              )}

              <button
                onClick={closeModal}
                aria-label="Fechar modal de presente"
                tabIndex={0}
                className="flex border py-2 px-4 mx-auto rounded-full my-4 text-[1.5em]"
              >
                X
              </button>
            </TabFocusTrap>
          </div>
        )}
      />
    </>
  );
};

export default MysteryBox;
