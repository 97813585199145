import { useConfig } from "context/ConfigContext";

const Logo = () => {
  const { appearance } = useConfig();

  return (
    <>
      <img
        src={appearance?.images?.logo ? appearance?.images?.logo : `/img/logo-trilha.png`}
        width={`100%`}
        className="relative lg:left-0 lg:top-[20px] z-20"
        tabIndex={0}
        aria-label="Logo Game tô on"
      />
    </>
  );
};

export default Logo;
