import BreadCrumb from 'components/BreadCrumb';
import Header from 'components/Header';
import { Challenge, Idea, Ranking, RankingCommunity } from 'components/Sidebar';
import { AuthContext } from 'context/AuthContext';
import { useContext, useEffect, useRef, useState } from 'react';
import './style.scss';
import { useLocation } from 'react-router-dom';
import destaqueGame from 'assets/img/destaqueGame.png';
import destaqueStory from 'assets/img/destaqueStory.png';
import { useConfig } from 'context/ConfigContext';

export interface ElementPros {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  [key: string]: any;
}
const Layout = ({ children, team }: ElementPros) => {
  const location = useLocation();

  const { breadcrumbItens, isAuthenticated, user, isSidebar, pageTitle, contrast, setContrast } = useContext(AuthContext);

  const titleRef = useRef<HTMLHeadingElement>(null);
  const pageRef = useRef<HTMLDivElement>(null);
  const { appearance } = useConfig();

  useEffect(() => {
    if (!!pageRef && !!pageRef.current) {
      // pageRef?.current?.querySelector("h2")?.focus();
      console.log('ischange', location.pathname);
    }
    document.body.scrollTo(0, 0);
    setTimeout(() => {
      document.getElementById('page-title')?.focus();
    }, 1000);
  }, [location.pathname]);

  useEffect(() => {
    console.log(contrast);
  }, [contrast]);

  // useEffect(() => {
  //   if (!!titleRef) {
  //     titleRef?.current?.querySelector('h2')?.focus();
  //   }
  // }, [titleRef]);

  const shouldRenderIdea = !location.pathname.startsWith('/ideias');

  const isMoreSpace =
    location.pathname.startsWith('/trilha') ||
    location.pathname.startsWith('/modulo') ||
    location.pathname.startsWith('/tabuleiro');

  return (
    <>
      <div
        className={`w-full wrapper-app md:px-0  ${!isAuthenticated ? 'no-logged' : 'logged  px-9 '} 'bg-blue h-screen'
        }`}
      >
        {isAuthenticated ? (
          <>
            <Header />
            <main className="flex w-full">
              <div className="container mt-4 mb-0 mx-auto z-[300]">
                {!!breadcrumbItens && breadcrumbItens.length > 1 && <BreadCrumb itens={breadcrumbItens} />}

                <div className={`flex flex-col lg:flex-row justify-between w-full mt-2 lg:mt-8 ${isMoreSpace ? '' : ''} lg:pb-[400px]`}>
                  <div className="w-full relative z-20" ref={pageRef}>
                    {/* {!!pageTitle && (
                      <h1
                        id="page-title"
                        className="text-yellow font-bold lg:text-4xl text-xl border-b border-yellow pb-1 relative mt-0 text-center lg:text-left mb-5"
                        tabIndex={0}
                      >
                        {pageTitle}
                      </h1>
                    )} */}
                    {children}
                  </div>

                  {isSidebar && !!appearance?.menu?.ranking && !!appearance?.menu?.challenge && (
                    <>
                      <aside className="w-[92%] lg:w-96 lg:ml-8 mx-0 mt-16 lg:mt-0 bg-red">
                        {shouldRenderIdea ? (
                          <>
                            {/* <Idea />
													<Ranking /> */}
                            <Challenge />
                          </>
                        ) : (
                          <>
                            {/* <RankingCommunity /> */}
                            {/* <RankingBB /> */}
                          </>
                        )}
                      </aside>
                    </>
                  )}
                </div>
              </div>
            </main>
          </>
        ) : (
          <>{children}</>
        )}
      </div>
    </>
  );
};

export default Layout;
