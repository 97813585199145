import BoardInfo from "classes/board.interface";
import TabFocusTrap from "components/TabFocusTrap";
import { useAuth } from "context/AuthContext";
import { FC, useEffect, useRef, useState } from "react";
import { AiFillStar } from "react-icons/ai";
import { FiChevronsRight } from "react-icons/fi";
import { TiTimes } from "react-icons/ti";
import { useNavigate } from "react-router";
import { Popover } from "react-tiny-popover";
import { toast } from "react-toastify";
import { actionLog } from "services/actionlog.service";
import { getFolderContent, registerFolderLog } from "services/elarning.service";
import { useModal } from "context/ModalContext";
import { DialogContent } from "utils/modal-board-data";

interface Options {
  dateToStart: string;
  allowStart?: boolean;
  isMobile?: boolean;
  color?: string;
  isActive?: boolean;
  enable?: boolean;
  index: number;
  number: number;
  item: BoardInfo;
  isNextMod?: boolean;
  openItem: (val?: any) => void;
  goNext: (val?: any) => void;
  onUpdate: () => void;
}

const BoardItem: FC<Options> = ({
  isMobile,
  color,
  isActive,
  index,
  number,
  item,
  goNext,
  onUpdate,
  isNextMod,
}: Options) => {
  const { showModalBoard, setShowModalBoard, setModalData } = useModal();
  const boardItemRef = useRef<HTMLDivElement>(null);

  const { user, refreshUser, contrast } = useAuth();
  const [animate, setAnimate] = useState(false);
  const [isCurrentGame, setIsCurrentGame] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const [buttonLabel, setButtonLabel] = useState("");

  const [currentIcon, setCurrentIcon] = useState("");

  useEffect(() => {
    setInterval(() => {
      setAnimate(true);
    }, 300 * index);
  }, [isActive]);

  useEffect(() => {
    if (!!boardItemRef && boardItemRef.current) {
      boardItemRef.current?.querySelector("button")?.focus();
    }
  }, [isPopoverOpen]);

  useEffect(() => {
    if (!showModalBoard) {
      focusOnCard();
    }
  }, [showModalBoard]);

  function viewDetail() {
    if (isCurrentGame) {
      let data = {};

      getFolderContent(item.type, item.content).then((resp) => {
        data = {
          ...data,
          ...{
            description: resp?.description,
            title: item.title,
            itemNumber: number,
            gameType: item.type,
            contentType: item.type,
            star: item.action_logs?.attributes?.stars || 0,
            gameId: item._id,
            type: item.type,
          },
        };
        if (item.log?.status === "done") {
          data = { ...data, ...{ status: "COMPLETE" } };
        } else {
          data = { ...data, ...{ status: "UNCOMPLETE" } };
        }
        setIsPopoverOpen(false);
        setModalData({
          number: number,
          dialogContent: data,
          type: item.type,
          onClose: onCloseBoardItem,
          onEvent: (e?: any) => {
            markView(e);
          },
        });
        setShowModalBoard(true);
      });
    } else {
      getFolderContent(item.type, item.content)
        .then((resp) => {
          let data = {};
          if (resp) {
            data = {
              ...data,
              ...{
                itemNumber: number,
                _id: item.content,
                _quiz_id: item._id,
              },
            };
            if (item.log?.status === "done") {
              data = { ...data, ...{ status: "COMPLETE" } };
            } else {
              data = { ...data, ...{ status: "UNCOMPLETE" } };
            }
            if (resp?.description) {
              data = { ...data, ...{ content: resp?.description } };
            }
            if (resp.text) {
              data = { ...data, ...{ content: resp?.text } };
            }
            if (resp?.image) {
              data = { ...data, ...{ image: resp?.image } };
            }
            if (resp?.title) {
              data = { ...data, ...{ title: resp?.title } };
            }
            if (resp?.intro) {
              data = { ...data, ...{ introContent: resp?.intro } };
            }
            if (resp?.url) {
              data = { ...data, ...{ url: resp?.url } };
            }

            if (resp?.link) {
              data = { ...data, ...{ url: resp?.link } };
            }
            if (resp?.file) {
              data = { ...data, ...{ file: resp?.file } };
            }

            data = {
              ...data,
              ...{
                contentType: item.type,
                star: item.action_logs?.attributes?.stars || 0,
              },
            };

            setModalData({
              number: number,
              dialogContent: data,
              type: item.type,
              onClose: onCloseBoardItem,
              onEvent: (e?: any) => {
                markView(e);
              },
            });
            setShowModalBoard(true);
          }
        })
        .finally(() => {
          setIsPopoverOpen(false);
        });
    }
  }

  const onCloseBoardItem = () => {
    setShowModalBoard(false);
    setModalData(undefined);
    focusOnCard();
    onUpdate();
    refreshUser();
    removeContentId();
  };

  async function markView(obj?: any) {
    if (item.log?.status !== "done" && !["external"].includes(item.type)) {
      await registerFolderLog(user?._id, item._id, "done");
      toast.info(
        `O ${item.title} foi concluído com sucesso! Seu progresso será atualizado!`,
        {
          onClose: () => {
            const bar = document.querySelector(
              `.user-progress-bar`
            ) as HTMLDivElement;
            bar.focus();
          },
        }
      );
    }

    const currentLog = {
      id_content: item._id,
      type: item.type,
    };
    if (!["quiz", "external"].includes(item.type)) {
      await actionLog("me", "complete_content", {
        ...currentLog,
        ...obj,
      });
    }

    if (["external"].includes(item.type)) {
      await actionLog("me", "view_course", currentLog);
    }

    if (["text"].includes(item.type)) {
      onUpdate();
    }
  }

  useEffect(() => {
    const isGame = [
      "game_wordsearch",
      "game_crossword",
      "game_puzzle",
      "game_story",
      "game_enigma",
      "game_memory",
      "game_codex",
      "game_runner",
    ].includes(item.type);

    if (isGame) {
      setButtonLabel("jogo");
    } else {
      switch (item.type) {
        case "doc":
          setButtonLabel("documento");
          setCurrentIcon("content.svg");
          break;
        case "text":
          setButtonLabel("conteúdo");
          setCurrentIcon("content.svg");
          break;
        case "external":
          setButtonLabel("curso");
          setCurrentIcon("extern.svg");
          break;
        case "video":
          setButtonLabel("video");
          setCurrentIcon("video.svg");
          break;
        default:
          setButtonLabel(item.type);
          setCurrentIcon(`${item.type}.svg`);
          break;
      }
    }

    setIsCurrentGame(isGame);
  }, [item.type]);

  function addContentId() {
    const url = new URL(String(window.location));
    url.searchParams.set("contentId", item._id);
    window.history.pushState({}, "", url);
  }

  function removeContentId() {
    const url = new URL(String(window.location));
    url.searchParams.set("contentId", "");
    window.history.pushState({}, "", url);
  }

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        removeContentId();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  const PointItem = () => {
    return (
      <>
        {item.action_logs?.attributes?.stars && (
          <>
            {Array.from({
              length: item.action_logs?.attributes?.stars,
            }).map((v: any, i: number) => (
              <div key={`star_${i}`} className="mr-0 lg:mr-1">
                <AiFillStar color="#FCFC30" size={isMobile ? "8" : "15"} />
              </div>
            ))}
          </>
        )}
        {item.log?.status !== "done" && (
          <>
            {Array.from({
              length: 3,
            }).map((v: any, i: number) => (
              <div key={`star_${i}`} className="mr-0 lg:mr-1 opacity-40">
                <AiFillStar color="#69696E" size={isMobile ? "8" : "15"} />
              </div>
            ))}
          </>
        )}
      </>
    );
  };

  const focusOnCard = () => {
    const card = document
      .getElementById(`card_${index}`)
      ?.querySelector("button");

    card?.focus();
  };

  const onClosePopOver = () => {
    console.log(" here");
    if (isPopoverOpen) {
      focusOnCard();

      setIsPopoverOpen(false);
    }
  };

  return (
    <>
      <Popover
        isOpen={isPopoverOpen}
        onClickOutside={onClosePopOver}
        positions={["bottom"]}
        content={
          <TabFocusTrap isOpen={isPopoverOpen} setIsOpen={onClosePopOver}>
            <div
              className={`${
                contrast ? "bg-black border border-yellow" : "bg-white"
              } pop-item shadow rounded-[10px] py-5 px-5 dialog transition-all justify-between relative min-w-[90%] max-w-[90%] lg:min-w-[250px] lg:max-w-lg`}
              ref={boardItemRef}
            >
              <div className="flex justify-between items-end mb-3 ">
                <div
                  className="stars mb-4 flex items-center justify-between"
                  aria-hidden={true}
                  tabIndex={-1}
                >
                  <h3
                    className={`font-bold text-2xl  ${
                      contrast ? "text-yellow" : "text-black"
                    }`}
                    aria-label={`Item ${number} do tabuleiro`}
                    aria-hidden={true}
                    tabIndex={-1}
                  >
                    {number}
                  </h3>
                  <div className="flex" aria-hidden={true} tabIndex={-1}>
                    {item.action_logs?.attributes?.stars && (
                      <>
                        {Array.from({
                          length: item.action_logs?.attributes?.stars,
                        }).map((v: any, i: number) => (
                          <div key={`star_${i}`} className="mr-1">
                            <AiFillStar color="#FCFC30" size={"25"} />
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>

                <button
                  onClick={onClosePopOver}
                  className="absolute top-[20px] right-[20px]"
                  aria-label="Fechar item do tabuleiro"
                >
                  <TiTimes
                    color={contrast ? "#fcfc30" : "#69696E"}
                    size={25}
                    aria-hidden={true}
                  />
                </button>
              </div>

              <h1
                className={`font-bold text-xl ${
                  contrast ? "text-yellow" : "text-black"
                } `}
                aria-hidden={true}
                tabIndex={-1}
                dangerouslySetInnerHTML={{
                  __html: item.title,
                }}
              ></h1>

              <button
                className={`${
                  contrast
                    ? "bg-black border border-yellow text-yellow"
                    : "bg-blue text-white"
                } p-3 text-center text-base rounded-[10px] mt-6`}
                onClick={() => viewDetail()}
                tabIndex={0}
                aria-label={`Abrir modal do ${buttonLabel}`}
              >
                Ver {buttonLabel}
              </button>
            </div>
          </TabFocusTrap>
        }
      >
        {isNextMod ? (
          <>
            <div
              className={`bg-yellow cursor-pointer on-start px-1 lg:px-3 lg:py-[14px] ${
                isNextMod ? "is-next" : ""
              }`}
              onClick={() => goNext()}
              tabIndex={0}
              role="button"
              aria-label="Próximo módulo"
            >
              <div
                className="flex flex-col text-gray-300 text-xs lg:text-base text-center justify-between items-end"
                aria-hidden={true}
              >
                Próximo <br className="block lg:hidden" /> Módulo{" "}
                <FiChevronsRight color={"#69696E"} size={25} />
              </div>
            </div>
          </>
        ) : (
          <div
            className={`cursor-pointer ${
              item.log?.status === "done"
                ? `bg-[#ffcc00] is-done`
                : "bg-white on-start"
            } lg:w-40 lg:h-40 w-16 h-20 rounded-[10px] m-[1px] ${
              animate ? "active" : ""
            } ${isCurrentGame ? `is-game` : `is-outher`}`}
            style={{
              background: item.log?.status === "done" ? color : "#EAE7F7",
            }}
            id={`card_${index}`}
          >
            {item.log?.status === "done" && (
              <div
                className="item-overlay rounded-[10px]"
                style={{ background: color }}
              ></div>
            )}

            <button
              className="flex items-start h-full relative bg-transparent border-none"
              aria-label={`Casa ${index + 1}: ${item.title} ${
                item.log?.status ? "- Concluído" : ""
              }`}
              tabIndex={0}
              onClick={() => setIsPopoverOpen(true)}
            >
              {item.log?.status === "done" && (
                <div
                  className="lg:h-40 lg:w-40 h-5 w-5 icon-checked"
                  aria-hidden={true}
                >
                  <img
                    src={require("../../../assets/images/board-icon/checked.png")}
                    width={"100%"}
                  />
                </div>
              )}
              {!!item.type && isCurrentGame ? (
                <>
                  <img
                    src={`/img/${item.type}.png`}
                    alt=""
                    className="rounded-[10px] image-game"
                    aria-hidden={true}
                  />
                  {!isCurrentGame && (
                    <div
                      className="flex items-center absolute z-[100] p-1 bg-white right-0 top-0"
                      aria-hidden={true}
                    >
                      {item.action_logs?.attributes?.coins && (
                        <>
                          {Array.from({
                            length: item.action_logs?.attributes?.coins,
                          }).map((v: any, i: number) => (
                            <img
                              key={`coins_${i}`}
                              className="relative coin mr-1"
                              src={require("../../../assets/img/moedas.png")}
                              alt="Moedas"
                            />

                            // </div>
                          ))}
                        </>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {!isCurrentGame && !!currentIcon && (
                    <img
                      src={require(`../../../assets/images/board-icon/${currentIcon}`)}
                      alt=""
                      className="rounded-[10px] icon-game"
                    />
                  )}
                  <div className="detail flex items-center relative z-[100]">
                    <p
                      className={`${
                        item.log?.status === "done"
                          ? "text-white"
                          : "text-gray-300"
                      } mr-1 lg:mr-[10px] font-extrabold text-xs lg:text-[22px]`}
                    >
                      {number}
                    </p>{" "}
                    <PointItem />
                  </div>
                </>
              )}
            </button>

            {!!item.image && <img src={item.image} />}
          </div>
        )}
      </Popover>
    </>
  );
};

export default BoardItem;
