import BoardInfo from "classes/board.interface";
import UserProgress from "components/UserProgress";
import { AuthContext, useAuth } from "context/AuthContext";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  getContent,
  getContentModule,
  getFolderAndLog,
  getModuleData,
  getProgress,
  getSingleTrail,
  // getTrails,
} from "services/elarning.service";
import BoardItem from "./BoardItem";
import { BoxPositions } from "./positions";

import { ProgressInfo, ProgressItemInfo } from "classes/progress.interface";
import TrailInfo from "classes/trail.interface";
import BB_ROUTES from "routes/const";

import { Settings } from "classes/settings.interface";
import { toast } from "react-toastify";
import "./style.scss";

const Board = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { user, team } = useContext(AuthContext);
  const { setBreadCrumbItens, setPageTitle, contrast } = useAuth();

  const [routeProps, setRouteProps] = useState<{
    moduleId: string;
    trailId: string;
    nextModule?: string;
  }>();

  const [globalSettings, setGlobalSettings] = useState<Settings>();

  const [itens, setItens] = useState<Array<BoardInfo>>([]);
  const [currentItem, setCurrentItem] = useState<BoardInfo>();
  const [currentTrail, setCurrentTrail] = useState<TrailInfo>();
  const [finaList, setFinalList] = useState<Array<BoardInfo>>([]);
  const [loading, setLoading] = useState(false);
  const [expectedPercent, setExpectedPercent] = useState(0);

  const [progress, setProgress] = useState<ProgressInfo>();
  const [currentProgress, setCurrentProgress] = useState<ProgressItemInfo>();

  const [isActive, setIsActive] = useState(false);

  const [isMobile, setIsMobile] = useState(false);

  const [showDialog, setShowDialog] = useState(false);
  const [dialogNumber, setDialogNumber] = useState(0);

  const [isReload, setIsReload] = useState(false);
  const [allowStart, setAllowStart] = useState(false);
  const [dateToStart, setDateToStart] = useState("");

  const [nextModuleUrl, setNextModuleUrl] = useState("");

  const [nextModule, setNextModule] = useState("");
  const [nextModuleContent, setNextModuleContent] = useState<TrailInfo>();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 960) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    setLoading(true);
    if (!!params && params.id) {
      const p = params.id.split("-");
      setRouteProps({
        moduleId: p[0],
        trailId: p[1],
      });
    }
  }, [params, user]);

  useEffect(() => {
    if (routeProps) {
      setLoading(true);

      loadGlobalSettings();
    }
  }, [routeProps, user?.extra]);

  async function loadGlobalSettings() {
    const currentDate = new Date();
    try {
      const module = await getModuleData(String(routeProps?.moduleId));
      const settings = await getSingleTrail(String(routeProps?.trailId));
      const content = await getContentModule(routeProps?.trailId);

      const current_item = content.findIndex(
        (v) => v._id === routeProps?.moduleId
      );

      let globalSettings = settings[0];

      const currentModule = content[current_item];

      setPageTitle(module?.title);

      setBreadCrumbItens([
        {
          isFirst: true,
          name: "Jornada/",
          active: false,
          path: "/trilha",
        },
        {
          isFirst: false,
          name: `${String(globalSettings?.title)}/`,
          active: false,
          path: `/modulo/${routeProps?.trailId}`,
        },
        {
          isFirst: false,
          name: " " + String(module?.title),
          active: true,
          path: `/modulo/${routeProps?.trailId}`,
        },
      ]);

      if (
        !!user?.extra &&
        currentModule?.extra.days_to_start >= 0 &&
        currentModule?.extra.days_to_complete >= 0
      ) {
        const admission = new Date(`${user?.extra.data_posse} 23:59:59`);
        const startDate = admission.setDate(
          admission.getDate() + currentModule.extra.days_to_start
        );

        const d = new Date(startDate);

        setDateToStart(new Intl.DateTimeFormat("pt-BR").format(d));
        if (currentDate.getTime() >= startDate) {
          setAllowStart(true);
        }
        globalSettings = {
          ...globalSettings,
          ...{
            start_period: startDate,
            end_period: d.setDate(
              d.getDate() + currentModule.extra.days_to_complete
            ),
            max_coin: currentModule.extra.max_coin || 0,
            max_star: currentModule.extra.max_star || 0,
          },
        };
      }

      if (globalSettings) {
        // console.log(globalSettings);
        setGlobalSettings(globalSettings);
        setTimeout(() => {
          setConfigs(globalSettings, module);
        }, 300);
      }

      const progress = await getProgress(
        String(user?._id),
        String(routeProps?.trailId)
      );

      if (current_item >= 0) {
        if (content[current_item + 1]) {
          setNextModuleContent(content[current_item + 1]);
          setNextModule(content[current_item + 1]._id);
        } else {
          setNextModule("");
        }
      }
      if (progress) {
        setProgress(progress);

        if (progress) {
          const moduleProgress = progress.items.filter((item) => {
            return item._id === routeProps?.moduleId;
          });
          if (!!routeProps?.moduleId && !!moduleProgress[0]) {
            setCurrentProgress(moduleProgress[0]);
          }
        }
      }
    } catch (e) {
      console.log(e);
    }

    loadData();
  }

  const allowNextModule = (item: TrailInfo) => {
    const currentDate = new Date();
    if (
      !!user?.extra &&
      item?.extra.days_to_start >= 0 &&
      item?.extra.days_to_complete >= 0
    ) {
      const admission = new Date(`${user?.extra.data_posse} 23:59:59`);
      const startDate = admission.setDate(
        admission.getDate() + item.extra.days_to_start
      );

      const d = new Date(startDate);

      setDateToStart(new Intl.DateTimeFormat("pt-BR").format(d));
      if (currentDate.getTime() >= startDate) {
        setAllowStart(true);
      }
    }
  };

  async function loadData() {
    try {
      const itens = await getFolderAndLog(
        String(user?._id),
        String(routeProps?.moduleId)
      );
      setItens(itens);
    } finally {
      setLoading(false);
    }
  }

  const setConfigs = (globalSettings: any, module: any) => {
    if (!!finaList && finaList.length > 0) {
      setBreadCrumbItens([
        {
          isFirst: true,
          name: "Jornada/",
          active: false,
          path: "/trilha",
        },
        {
          isFirst: false,
          name: `${String(globalSettings?.title)}/`,
          active: false,
          path: BB_ROUTES.MODULE(routeProps?.trailId),
        },
        {
          isFirst: false,
          name: String(module.title),
          active: true,
          path: "",
        },
      ]);
      setPageTitle(module.title);
    }
  };

  useEffect(() => {
    setIsMobile(window.innerWidth <= 960 ? true : false);
  }, [itens, currentTrail, currentProgress, globalSettings]);

  useEffect(() => {
    const list: Array<BoardInfo> = [];
    const position = BoxPositions;
    itens.forEach((item: BoardInfo, index: number) => {
      if (position[index]) {
        let enabled = false;

        if (index === 0) {
          enabled = true;
        } else {
          const enableItem = itens[index - 1];
          if (
            ![
              "game_wordsearch",
              "game_crossword",
              "game_puzzle",
              "game_story",
              "game_enigma",
              "game_memory",
              "game_codex",
              "game_runner",
            ].includes(enableItem.type)
          ) {
            enabled = true;
          } else {
            enabled = itens[index - 1].log?.status === "done" ? true : false;
          }
        }

        const newItem: BoardInfo = {
          ...item,
          ...{
            boardPosition: isMobile
              ? position[index].mobile
              : position[index].desktop,
            enable: enabled,
            achievement: [],
          },
        };
        list.push(newItem);
      }
    });

    if (!!routeProps && !!nextModule) {
      if (list.length > 0 && !!list[list.length - 1].boardPosition) {
        const newPosition = Object.assign(
          {},
          list[list.length - 1].boardPosition
        );
        if (newPosition?.top) {
          const sumDistance = isMobile ? 82 : 163;
          newPosition.top = Number(newPosition?.top) + sumDistance;
        }

        console.log(nextModule);
        const nextUrl = BB_ROUTES.BOARD(`${nextModule}-${routeProps.trailId}`);

        setNextModuleUrl(nextUrl);

        const nextMod: BoardInfo = {
          title: "Próximo <br /> Módulo",
          nextModule: true,
          content: "",
          boardPosition: newPosition,
          parent: "",
          type: "",
          extra: "",
          image: "",
          position: 0,
          log: undefined,
          _id: nextModule,
        };
        list.push(nextMod);
      }
    }

    setFinalList(list);
  }, [itens, isMobile]);

  function reload() {
    setIsReload(true);

    loadGlobalSettings();
    loadData();
  }

  const isAllowNextModule = () => {
    const currentDate = new Date();
    if (
      !!user?.extra &&
      nextModuleContent?.extra.days_to_start >= 0 &&
      nextModuleContent?.extra.days_to_complete >= 0
    ) {
      const admission = new Date(`${user?.extra.data_posse} 23:59:59`);
      const startDate = admission.setDate(
        admission.getDate() + nextModuleContent?.extra.days_to_start
      );

      const d = new Date(startDate);

      setDateToStart(new Intl.DateTimeFormat("pt-BR").format(d));
      if (currentDate.getTime() >= startDate) {
        return true;
      } else {
        return false;
      }
    }
  };

  const goNext = (url?: string) => {
    if (isAllowNextModule()) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setLoading(true);
      setFinalList([]);
      navigate(String(url));
    } else {
      toast.warning(
        `O modulo ${nextModuleContent?.title} ainda não esta disponível!`
      );
    }
  };

  const openItem = (item: BoardInfo, n: number) => {
    setDialogNumber(n);
    setCurrentItem(item);
    setShowDialog(true);
  };

  return (
    <>
      {!!finaList && finaList.length > 0 ? (
        <>
          <div className={`board w-full lg:mt-28 mt-24`}>
            {!!globalSettings && (
              <>
                <div
                  className={
                    expectedPercent ? "mb-11 lg:mb-32" : "mb-11 lg:mb-11"
                  }
                >
                  <UserProgress
                    startDate={globalSettings?.start_period}
                    endDate={globalSettings?.end_period}
                    barColor={
                      globalSettings?.extra.color.includes("#")
                        ? globalSettings?.extra.color
                        : `#${globalSettings?.extra.color}`
                    }
                    currentProgress={currentProgress?.percent || 0}
                    expectedProgress={0}
                    expectedDays={0}
                  />
                </div>
                <div className="w-full mb-5">
                  <p className={contrast ? "text-yellow" : "text-white"}>
                    Você poderá ganhar até 3 estrelas por conteúdo e até 3
                    moedas por game, se concluir esse módulo até{" "}
                    <strong>
                      {new Intl.DateTimeFormat("pt-BR").format(
                        globalSettings?.end_period
                      )}
                    </strong>
                  </p>
                </div>
              </>
            )}

            <div
              className={`itens relative`}
              style={{
                height: isMobile ? (Number(finaList.length) * 80) / 1.5 : "",
              }}
            >
              {finaList.map((item, index: number) => (
                <React.Fragment key={`${index}_${item._id}`}>
                  <div
                    className={`absolute
                  ${
                    item.boardPosition?.radius ? item.boardPosition?.radius : ""
                  }`}
                    style={{
                      left: item.boardPosition?.left,
                      top: item.boardPosition?.top,
                    }}
                  >
                    <BoardItem
                      dateToStart={dateToStart}
                      allowStart={allowStart}
                      color={
                        globalSettings?.extra.color.includes("#")
                          ? globalSettings?.extra.color
                          : `#${globalSettings?.extra.color}`
                      }
                      enable={item.enable}
                      isMobile={isMobile}
                      isActive={isActive}
                      number={index + 1}
                      isNextMod={item.nextModule}
                      index={index}
                      item={item}
                      goNext={() => goNext(nextModuleUrl)}
                      openItem={(e) => openItem(item, index + 1)}
                      onUpdate={() => console.log("reload")}
                    />
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={contrast ? "text-yellow" : "text-white"}>
            {loading ? (
              "Carregando..."
            ) : (
              <div className="text-center">
                <h3 className="text-center font-bold text-3xl text-white">
                  Ops!
                </h3>
                <p className="text-base">
                  Esta jornada não esta disponível para você!
                </p>
                <button
                  className="bg-purple text-white text-base rounded-xl px-3 py-2 mt-3 hover:opacity-80 translate-x-3"
                  onClick={(e) => navigate(BB_ROUTES.TRAIL())}
                >
                  Ir para Jornadas
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Board;
