import { api } from './api';


export async function verifyUnlockMystery(userId: string) {
    const payload = [
      {
        "$match": {"player": userId}
      }
    ];
    const { data } = await api.post("database/verify_unlock_mystery_box__c/aggregate", payload);
    return data;
}

export async function updateUnlockMystery(item: any) {
    const { data } = await api.put("database/verify_unlock_mystery_box__c", item);
    return data;
}

export async function execMysteryBox(userId: string) {
    const { data } = await api.post(`mystery/execute_by_player/${userId}`, {"_id": "64f0acf3de034160fec9d80f"});
    return data;
}