import { Configuration, PopupRequest } from '@azure/msal-browser';

// We will receive msalConfig from the caller, so we can export a function
export function createMsalConfig(clientId: string, tenantId: string, url: string): Configuration {
  return {
    auth: {
      clientId: clientId,
      authority: `https://login.microsoftonline.com/${tenantId}`,
      redirectUri: url,
      postLogoutRedirectUri: '/',
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
    },
  };
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: ['User.Read'],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
