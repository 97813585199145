import Skeleton from 'components/Skeleton';
import { AuthContext, useAuth } from 'context/AuthContext';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import BB_ROUTES from 'routes/const';
import { updateUserProfile } from 'services/profile.service';
import { GetRegulamento } from 'services/regulation.service';
import './style.scss';

const Rule = () => {
  // const { user, refreshUser } = useContext(AuthContext);
  const ruleRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { user, refreshUser, setBreadCrumbItens, setPageTitle, contrast } = useAuth();

  const [loading, setLoading] = useState(false);
  const [dataRule, setDataRule] = useState<any>({});

  useEffect(() => {
    setBreadCrumbItens([
      {
        isFirst: true,
        name: 'Inicio/',
        active: false,
        path: BB_ROUTES.HOME(),
      },
      {
        isFirst: false,
        name: `Regulamento`,
        active: true,
        path: '/',
      },
    ]);
    getData();
    setPageTitle('Regulamento');
  }, [setBreadCrumbItens]);

  async function getData() {
    setLoading(true);
    GetRegulamento()
      .then(resp => {
        if (resp.length > 0) {
          setDataRule(resp[0]);
        }
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 300);
      });
  }

  async function accept() {
    await updateUserProfile({
      _id: user?._id,
      extra: {
        ...user?.extra,
        ...{
          accept_terms: true,
          accept_terms_in: formatDate(new Date()),
        },
      },
    });

    await refreshUser();
    setTimeout(() => {
      navigate(BB_ROUTES.TRAIL());
    }, 2000);
  }

  function formatDate(date: any | Date | undefined) {
    const dateFormatter = new Intl.DateTimeFormat('en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    return dateFormatter.format(date);
  }

  function downloadFromURL() {
    fetch(dataRule.arquivo.url)
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'regulamento';

        document.body.appendChild(link);
        link.click();

        URL.revokeObjectURL(link.href);

        document.body.removeChild(link);
      })
      .catch(error => {
        console.error('Erro ao baixar o arquivo:', error);
      });
  }

  return (
    <div className="w-full text-white text-xl font-lighter main-regulamento mt-11 lg:mt-0">
      {loading ? (
        <p className="text-white text-base text-center p-4">Carregando...</p>
      ) : (
        <>
          <div
            className={`content lg:max-h-80 max-h-72 overflow-auto ${contrast ? 'has-contrast' : ''}`}
            tabIndex={0}
            ref={ruleRef}
            dangerouslySetInnerHTML={{ __html: dataRule.texto }}
          ></div>

          {!user?.extra.accept_terms ? (
            <div className="actionsbottom flex flex-col lg:flex-row">
              <button onClick={accept} className={contrast ? 'bg-black border-yellow text-yellow' : 'bg-white text-purple'}>
                Aceitar termos
              </button>
              <button
                className={`btn-open-add ${contrast ? 'bg-black border-yellow border-2 text-yellow' : 'bg-white text-purple'}`}
                onClick={downloadFromURL}
              >
                Fazer download
              </button>
              <button>Cancelar</button>
            </div>
          ) : (
            <>
              <div className={`mt-4 py-4 border-t ${contrast ? 'border-yellow text-yellow' : 'border-white text-white'}`}>
                Regulamento aceito em: {new Intl.DateTimeFormat('pt-br').format(new Date(user.extra.accept_terms_in))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Rule;
